import React, { useState } from 'react';
import BrandLogo from '../utils/BrandLogo';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);


  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);

    if (isMenuOpen && typeof window !== 'undefined' && window.document) {
      document.body.style.overflow = 'unset';
    } else {
      document.body.style.overflow = 'hidden';
    }
  };



  const closeMenu = () => {
    setMenuOpen(false);
    if (typeof window !== 'undefined' && window.document) {
      document.body.style.overflow = 'unset';
    }
  };

  return (
    <>
      <nav className="relative py-3 px-8 lg:py-4 lg:px-10 flex justify-between items-center bg-white max-w-[1400px] mx-auto">
        <a className="text-3xl font-bold leading-none" href="#">
          <BrandLogo />
        </a>
        <div className="lg:hidden">
          <button
            className="navbar-burger flex items-center text-blue-600 "
            onClick={toggleMenu}
          >
            <svg className="block h-7 w-7 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Mobile menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>

        <div className={`hidden lg:flex flex-col gap-2 lg:items-end lg:w-auto lg:space-x-6 ${isMenuOpen ? '' : 'hidden'}`}>
          <div style={{display:'flex',paddingRight:'30px'}}>
          {/* <Link className="hidden lg:inline-block px-3 text-sm text-[#477AF6] hover:text-blue rounded-xl transition duration-200" to="sign-up">
            Signup
          </Link> */}
          <Link target="_blank"   className="hidden lg:inline-block text-sm text-[#477AF6] hover:text-blue rounded-xl transition duration-200" to="https://portal.1inbox.ai/">
            Login
          </Link>
          </div>
          {/* className="text-sm text-[#262250] hover:text-gray-500 lg:mr-7 " */}
          <div className="lg:mt-3" style={{ display: 'flex', textAlign: 'center', alignItems: 'center' }}>
          <Contactdiv
          >
            <Link to="/contact-us" onClick={closeMenu}>
              Contact
            </Link>
          </Contactdiv>
          <Buydiv
            
          >
            <Link to="/pricing" onClick={closeMenu}>
              Buy Now
            </Link>
          </Buydiv>
        </div>

        </div>
      </nav>



      <div className={`navbar-menu relative z-50 ${isMenuOpen ? '' : 'hidden'}`}>
        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25" onClick={toggleMenu}></div>
        <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
          <div className="flex items-center mb-8">
            <a className="mr-auto text-3xl font-bold leading-none" href="#">
              <BrandLogo />
            </a>
            <button className="navbar-close" onClick={toggleMenu}>
              <svg className="h-8 w-8 text-gray-400 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <div>
            <ul>
              <li className="mb-1">
                <Link className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded" to="/contact-us" onClick={closeMenu}>
                  Contact Us
                </Link>
              </li>
              <li className="mb-1">
                <Link className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded" to="/pricing" onClick={closeMenu}>
                  Buy Now
                </Link>
              </li>
            </ul>
          </div>
          <div className="mt-auto">
            <div className="pt-6">
              {/* <Link className="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold leading-none bg-gray-50 hover:bg-gray-100 rounded-xl" to="sign-up" onClick={closeMenu}>
                Sign Up
              </Link> */}
              <a className="block px-4 py-3 mb-2 leading-loose text-xs text-center text-white font-semibold bg-blue-600 hover:bg-blue-700 rounded-xl" target="_blank"   href="https://portal.1inbox.ai/" onClick={closeMenu}>
              Login
              </a>
            </div>
            <p className="my-4 text-xs text-center text-gray-400">
              <span>Copyright © 2024</span>
            </p>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;








const Buydiv = styled.div`
  // margin-left: rem;
  padding: 0.5rem 1.5rem;
  border: 1px solid transparent;
  border-radius: 50px;
  transition: border-color 0.3s ease, color 0.3s ease, padding 0.3s ease;
  box-sizing: content-box;

  &:hover,
  &.buy-active {
    border-color: #477AF6;
    color: #477AF6;
    padding: 0.5rem 1.5rem;
  }
`;

const Contactdiv = styled.div`
  margin-left: 1rem;
  padding: 0.5rem 1.5rem;
  border: 1px solid transparent;
  border-radius: 50px;
  transition: border-color 0.3s ease, color 0.3s ease, padding 0.3s ease;
  box-sizing: content-box;

  &:hover,
  &.contact-active {
    border-color: #477AF6;
    color: #477AF6;
    padding: 0.5rem 1.5rem;
  }
`;
