import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';


const PaymentFailureContainer = styled.div`
  margin-top:40px !important;
  text-align: center;
  margin: 0 auto;
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 20px;
  box-shadow: #525be8 0px 0px 26px -15px;
`;

const PaymentFailureHeading = styled.h1`
  color: #e74c3c;
  font-size:25px;
  font-weight:500px;
`;

const PaymentFailureText = styled.p`
  margin: 10px 0;
  font-size: 18px;
`;

const HomeLink = styled.a`
  display: block;
  margin-top: 20px;
  text-decoration: underline;
  color: #3498db;
  cursor: pointer;
`;

const PaymentFailurePage = () => {
  const navigate = useNavigate();
    return (
        <PaymentFailureContainer>
            <PaymentFailureHeading>Payment Failed</PaymentFailureHeading>
            <PaymentFailureText>Your payment was not successful. Please try again later.</PaymentFailureText>
            {/* <HomeLink onClick={() => {
              //  navigate('/');
                window.close();
                // Close the window and send a message to the parent window
                if(window.opener) {
                  window.opener.postMessage('paymentFailed', '*');
                }
            }}>Go back to Home</HomeLink> */}
        </PaymentFailureContainer>
    );
}

export default PaymentFailurePage;
