import React from "react";

class MessageInputConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.state = {
      //   style: {
      //     // width: "330px",
      //     // height: "186px",
      //     // alignContent: "center",
      //     // borderRadius: "11px",
      //     // margin: "auto",
      //   },
    };
  }
  render() {
    let {
      className,
      bgcolor = "",
      marginLeft = "",
      question,
      confirm,
      cancel,
      styleData,
    } = this.props;
    return (
      <div style={styleData}>
        <diV
          class={className}
          style={{
            display: "flex",
            width: "339px",
            height: "194px",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "45px",
            borderRadius: "11px",
            padding: "0px",
            backgroundColor: bgcolor ? bgcolor : "",
            marginLeft: marginLeft ? marginLeft : "",
          }}
        >
          <div style={{ width: "330px", height: "186px" }}>
            <diV
              style={{
                backgroundColor: bgcolor ? "#4891DB" : "#E4E2E2",
                height: "148px",
                borderRadius: "11px",
                padding: "7px",
                overflow: "auto",
              }}
            >
              {question}
            </diV>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: bgcolor ? "#4891DB" : "#E4E2E2",
                  width: "47%",
                  height: "30px",

                  borderRadius: "11px",
                  textAlign: "center",
                }}
              >
                {confirm}
              </div>
              <div
                style={{
                  backgroundColor: bgcolor ? "#4891DB" : "#E4E2E2",
                  width: "47%",
                  height: "30px",
                  borderRadius: "11px",
                  textAlign: "center",
                }}
              >
                {cancel}
              </div>
            </div>
          </div>
        </diV>
      </div>
    );
  }
}

export default MessageInputConfirm;
