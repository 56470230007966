// import React, { Component } from "react";
// import pdfjs from "pdfjs-dist";

// // const canvasRef = useRef(null);
// export default class MessageInputDocuments extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       canvasRef: React.createRef(),
//     };
//   }
//   componentDidMount() {
//     async function renderPDF() {
//       const loadingTask = pdfjs.getDocument(
//         "https://i.pinimg.com/originals/a7/12/ab/a712ab6b5dba5cd1d02acd56f4aac8a6.png"
//       );
//       const pdf = await loadingTask.promise;
//       const page = await pdf.getPage(1);
//       const viewport = page.getViewport({ scale: 1 });
//       const canvas = this.state.canvasRef.current;
//       const context = canvas.getContext("2d");
//       canvas.height = viewport.height;
//       canvas.width = viewport.width;
//       const renderTask = page.render({
//         canvasContext: context,
//         viewport: viewport,
//       });
//       await renderTask.promise;
//     }
//     renderPDF();
//   }

//   render() {
//     return <canvas ref={this.state.canvasRef} />;
//   }
// }

// import React, { Component } from "react";
// import { ImportFile } from "modules/theme";
// import { FiDownload } from "react-icons/fi";
// import { Button } from "modules/theme";

// export default class MessageInputDocuments extends Component {
//   render() {
//     return (
//       // <Button
//       //   btnStyle="link"
//       //   onClick={() => {
//       //     console.log("Log for download");
//       //   }}
//       //   style={{ verticalAlign: "middle", color: "red" }}
//       //   href={
//       //     "https://i.pinimg.com/originals/a7/12/ab/a712ab6b5dba5cd1d02acd56f4aac8a6.png"
//       //   }
//       //   target="_blank"
//       // >
//       //   <FiDownload />
//       // </Button>
//       // <div style={{ marginBottom: "0", backgroundColor: "red" }}>
//       //   <div style={{ boxShadow: "0 1px 0.5px #005c4b" }}>
//       //     <span></span>
//       //     <div
//       //       style={{
//       //         paddingRight: "3px",
//       //         paddingLeft: "3px",
//       //         paddingBottom: "25px",
//       //         width: "336px",
//       //         paddingTop: "3px",
//       //         boxSizing: "border-box",
//       //       }}
//       //     >
//       //       <button
//       //         style={{
//       //           borderTopRightRadius: "6px",
//       //           borderTopLeftRadius: "6px",
//       //           paddingLeft: "0",
//       //           paddingRight: "0",
//       //           borderRight: "0",
//       //           borderLeft: "0",
//       //           backgroundColor: "transparent",
//       //           textAlign: "inherit",
//       //           fontFamily: "inherit",
//       //           overflowY: "hidden",
//       //           paddingBottom: "0",
//       //           fontSize: "100%",
//       //           display: "flex",
//       //           width: "100%",
//       //           paddingTop: "0",
//       //           color: "inherit",
//       //           overflowX: "hidden",
//       //           flexDirection: "column",
//       //           alignItems: "stretch",
//       //           boxSizing: "border-box",
//       //           cursor: "pointer",
//       //           borderBottom: "0",
//       //           borderTop: "0",
//       //           outline: "none",
//       //           padding: "0",
//       //           cursor: "pointer",
//       //           background: "none",
//       //           border: "0",
//       //         }}
//       //       >
//       //         <div
//       //           style={{
//       //             borderBottomRightRadius: "6px",
//       //             borderTopRightRadius: "6px",
//       //             borderTopLeftRadius: "6px",
//       //             borderBottomLeftRadius: "6px",
//       //             backgroundColor: "#025144,",
//       //             overflowY: "hidden",
//       //             width: "100%",
//       //             overflowX: "hidden",
//       //             cursor: "pointer",
//       //             textDecoration: "none",
//       //           }}
//       //         >
//       //           <div
//       //             style={{
//       //               paddingLeft: "19px",
//       //               paddingRight: "19px",
//       //               flexDirection: "row",
//       //               alignItems: "flex-start",
//       //               display: "flex",
//       //               minHeight: "0",
//       //               paddingTop: "13px",
//       //               minWidth: "0",
//       //               justifyContent: "flex-start",
//       //               paddingBottom: "13px",
//       //             }}
//       //           >
//       //             <div
//       //               style={{
//       //                 flexGrow: "0",
//       //                 flexShrink: "0",
//       //                 minHeight: "0",
//       //                 flexShrink: "1",
//       //                 flexBasis: "auto",
//       //                 minWidth: "0",
//       //                 order: "0",
//       //                 alignSelf: "auto",
//       //               }}
//       //             >
//       //               <div
//       //                 style={{
//       //                   backgroundRepeat: "no-repeat",
//       //                   backgroundSize: "contain",
//       //                   height: "30px",
//       //                   width: "26px",
//       //                 }}
//       //               ></div>
//       //             </div>
//       //             <div
//       //               style={{
//       //                 flexGrow: "1",
//       //                 marginRight: "10px",
//       //                 marginLeft: "10px",
//       //                 flexGrow: "0",
//       //                 marginTop: "0",
//       //                 minHeight: "0",
//       //                 marginBottom: "0",
//       //                 flexShrink: "1",
//       //                 flexBasis: "auto",
//       //                 minWidth: "0",
//       //                 order: "0",
//       //                 alignSelf: "auto",
//       //                 top: "-2px",
//       //                 position: "relative",
//       //               }}
//       //             >
//       //               <div
//       //                 style={{
//       //                   overflowY: "hidden",
//       //                   wordBreak: "break-all",
//       //                   textOverflow: "ellipsis",
//       //                   overflowX: "hidden",
//       //                 }}
//       //               >
//       //                 <span dir="auto" aria-label="">
//       //                   404_Error.png
//       //                 </span>
//       //               </div>
//       //               <div>
//       //                 <span data-meta-key="type" data-testid="type" title="PNG">
//       //                   PNG
//       //                 </span>
//       //                 <span>•</span>
//       //                 <span title="66 kB">66 kB</span>
//       //                 <span>•</span>
//       //               </div>
//       //             </div>
//       //             <div style="flex-grow: 0; flex-shrink: 0;"></div>
//       //           </div>
//       //         </div>
//       //       </button>
//       //     </div>
//       //     <span></span>
//       //     <div style={{ right: "5px" }}> </div>
//       //   </div>
//       // </div>
//       <h1></h1>
//     );
//   }
// }

import React, { Component } from "react";
// import { Attachment } from "../../../theme";

export default class MessageInputDocuments extends Component {
  render() {
    let {
      // className="",
      // bgcolor = "",
      // marginLeft = "",
      src,
      fileName=""
    } = this.props;

    console.log(fileName);
    return (
        <div className="document_preview">
          <a href={src} target="_blank" 
          style={{ display: "flex", 
          justifyContent: "space-between", 
          justifyItems: "center",
          alignItems: "center",
          
          }}>
            <span>{fileName || "File"}</span>
            <i className="fa fa-download 2fx"></i>
          </a>
        </div>
    );
  }
}
