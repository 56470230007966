import React from "react";
import styled from "styled-components";
import RedirectToPages from "../../../utils/RedirectToPages";
import theme from "../../../../assets/styles/theme/theme";
import { fadeIn } from "../../../../assets/styles/animation";
import { FaTimes } from "react-icons/fa";
import Heading from "../../../utils/Heading";
import { Link } from "react-router-dom";
import { useState } from "react";

const CommonDiv1 = ({
  title,
  description,
  bgimage,
  fgvideo,
  fgimage,
  btns,
  video,
}) => {

  const [isVideoOpen, setIsVideoOpen] = useState(false);  

  const handleOpenVideo = () => {
    setIsVideoOpen(true);
  };

  const handleCloseVideo = () => {
    setIsVideoOpen(false);
  };


  return (
    <>
        <FlexContainer>
          {/* <MainContainer> */}

          <TextContainer>
            <Containerr>
            <Heading level={1}>
              
            Unified Omnichannel Conversations your Key To Customer Success.
            </Heading>
            </Containerr>
            {/* <h1>
             
            </h1> */}
            <p dangerouslySetInnerHTML={{ __html: description }}>
              {/* {description}s */}
            </p>
            <BtnsWrapper>
              {/* {btns &&
                btns.map((item, ind) => {
                  return (
                    <RedirectToPages href="contact-us">
                      <Button
                        text={item}
                        key={ind}
                        bgColor={theme.colors.primary}
                      />
                    </RedirectToPages>
                  );
                })} */}

              <RedirectToPages href="contact-us">
                <HeroButton>Get In Touch</HeroButton>
              </RedirectToPages>

              <LinkText to="/pricing">Start Trial</LinkText>
              <div style={{cursor:'pointer'}} onClick={handleOpenVideo}>
              <a className="play-btn"></a>
              </div>
            </BtnsWrapper>
          </TextContainer>
          {/* </MainContainer> */}

          <ImageColumn>
            {!video && <img src={fgimage} alt="" />}
            {/* {video &&
              (fgimage && fgvideo ? (
                <BrowserCompatibility image={fgimage} video={fgvideo} />
              ) : null)} */}
          </ImageColumn>
        </FlexContainer>

      {isVideoOpen && (
        <VideoContainer>
          <CloseButton onClick={handleCloseVideo}>
            <FaTimes  />
          </CloseButton>

          <VideoIframe
        src={`https://www.youtube.com/embed/VR8xbmia3-o?autoplay=1`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
    />
        </VideoContainer>
      )}



    </>
  );
};

export default CommonDiv1;


const Containerr = styled.div`
@media (min-width: 1005px) and (max-width: 1302px) {
  margin-top: 3rem;
}
`;


const VideoContainer = styled.div`
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.8);
display: flex;
align-items: center;
justify-content: center;
z-index: 999;

padding: 6px;
  video {
    width: 100%;  
    @media(min-width: 768px){
      max-width: 100%;
      max-height: 100%;
      
    }
  }


  @media(min-width: 768px){
    // height: 100%;
  }
`;


const CloseButton = styled.div`
position: absolute;
top: 9%;
right: 23px;
cursor: pointer;
color: white;
z-index: 1000;
background-color: grey;
padding: 6px;

  svg {
  
      color: black;
    &:hover {
      transform: scale(1.2);
    }
  }

  @media(min-width: 768px){
    svg {
      width: 50px;
      color: white;
    }

    top: 5px;
    right: 5px;
    left: unset;

  }
`;

const VideoIframe = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`;


const FlexContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  // gap: 5rem;
  // gap: 1rem;

  @media (min-width: 1020px) {
    flex-direction: ${(props) => props.flxdrn || "row"};
  }

`;

const TextContainer = styled.div`
  // text-align: left;
  text-align: center;
  margin: 0 auto;
  flex: 1;
  // padding: 0px calc(16px + 1.5625vw);

  // padding: 15px;

  @media (min-width: 720px) {
  }
  @media (min-width: 1020px) {
    flex-direction: row-reverse;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-top: -5rem;
    // padding-left:10rem;

    padding: calc(16px + 1.5625vw) 0px;
    padding-left: calc(16px + 1.5625vw);


  }

  h1 {
    margin-bottom: 1rem;
    color: ${theme.colors.secondary};
    font-size: ${theme.fontSizes.heading2};
    animation: ${fadeIn} 1s ease-in-out;
    font-weight: 600;

    @media (min-width: 1020px) {
      font-size: ${theme.fontSizes.heading1};
      max-width: 590px;
      font-size: ${theme.fontSizes.heading1};
    }
  }

  p {
    font-size: ${theme.fontSizes.paragraph2};
    font-size: ${theme.fontSizes.paragraph3};
    color: ${theme.colors.lightgrey};
    animation: ${fadeIn} 1s ease-in-out;
  }
`;

const ImageColumn = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  display: grid;
  place-items: center;
  background-position-x: -113px;
  flex: 1.4;

  @media (min-width: 720px) {
    background-position-x: unset;
    background-position: center;
  }

  @media (min-width: 1020px) {
    background-image: none !important;
    padding: calc(16px + 1.5625vw) 0px;
    padding-right: calc(16px + 1.5625vw);
    
    // width: 35%;
  }

  img {
    // max-width: 350px;
    height: auto;

    @media (min-width: 1020px) {
      // margin-right: -840px;
      // margin-left:-600px;

      padding-right: 0;
      max-width: 780px;
      width: 100%;
    }
  }

  video {
    width: 100%;
    background-color: transparent;
  }

    @media (min-width: 720px) {
      // width: 580px;
    }
  }
`;

const BtnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;

  @media (min-width: 1020px) {
    justify-content: flex-start;
  }
`;

const HeroButton = styled.div`
  background-color: ${theme.colors.primary};
  border-radius: 14px;
  border: medium;
  padding: 10px 11px;
  font-weight: bold;
  font-size: 12px;
  color: white;
  outline: none;
  border: none;

  @media (min-width: 1020px) {
  font-size: 15px;
  border-radius: 50px;
  border: medium;
  padding: 15px 35px;
  font-weight: bold;
  font-size: 12px;
  color: white;
  outline: none;
  border: none;
  }
`;

const LinkText = styled(Link) `
  text-decoration: none;
  color: ${theme.colors.primary};
`;