import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MainContainer } from "../../assets/styles/theme/styles";
import Heading from "./Heading";
import theme from "../../assets/styles/theme/theme";
import styled from "styled-components";
import Images from "./Images";


function Carousel() {
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <ArrowRight
                className={className}
                style={{ ...style, }}
                onClick={onClick}
            >
            </ArrowRight>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <ArrowLeft
                className={className}
                style={{ ...style, }}
                onClick={onClick}
            >
            </ArrowLeft>
        );
    }


    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };




    const weHelpItems = [
        {
            title: "Operations",
            description: `Coordinate internal communications and requests from employees across different channels.
            Improve operational efficiency by providing a unified platform for team collaboration.`,
            img_url: Images.opr

        },

        {
            title: "Financial Services",
            description: "Ensure compliance by managing and archiving customer communications from various channels. Provide financial advisors with a unified view of client interactions across platforms.",
            img_url: Images.fc
        },
        {
            title: "Hospitality ",
            description: "Centralize guest requests and feedback from various communication channels. Improve hotel staff efficiency in responding to guest inquiries and requests.",
            img_url: Images.ho
        },
        {
            title: "Healthcare",
            description: "Coordinate patient communications from different channels for holistic patient care. Facilitate efficient handling of appointment scheduling and medical inquiries.",
            img_url: Images.healthcare
        },
        {
            title: "Retail",
            description: `Consolidate customer feedback and inquiries from various online and offline channels.
            Provide a unified platform for managing product-related inquiries and support.`,
            img_url: Images.retail
        },
        {
            title: "Telecommunications",
            description: `Manage customer service inquiries from different channels, such as calls, emails, and social media.
            Improve communication service providers' efficiency in handling service-related issues.`,
            img_url: Images.tele

        },
        {
            title: "Travel and Tourism",
            description: `Centralize communication regarding bookings, cancellations, and travel inquiries.
            Enhance customer satisfaction by providing a seamless communication experience.`,
            img_url: Images.travel

        },
        {
            title: "Automotive",
            description: `Streamline customer inquiries about vehicle purchases, service requests, and feedback.
            Improve the efficiency of customer support teams in addressing automotive-related concerns.`,
            img_url: Images.auto

        },
        {
            title: "Education",
            description: `Centralize student inquiries and support requests from various communication channels.
            Improve communication between educational institutions, students, and parents.`,
            img_url: Images.edu

        },
        {
            title: "Customer Support",
            description: `Unify customer support tickets, inquiries, and feedback from email, chat, and social media.
            Improve response times and overall customer satisfaction with a consolidated view.`,
            img_url: Images.cus

        },
        {
            title: "Sales",
            description: `Consolidate leads and customer interactions from various channels to streamline the sales process.
            Provide sales representatives with a comprehensive view of customer engagement.`,
            img_url: Images.sales

        },
        {
            title: "Marketing",
            description: `Aggregate customer feedback and inquiries from different marketing channels.
            Enhance marketing campaign responsiveness by centralizing customer interactions.`,
            img_url: Images.mar

        },
        {
            title: "E-commerce",
            description: "Streamline order-related communication from diverse channels for efficient orde processing.Enhance customer support by consolidating inquiries from website chats, emails, and social media.",
            img_url: Images.ec
        },
        {
            title: "Compliance and Record-keeping",
            description: `Ensure a centralized record of all customer interactions for compliance and auditing purposes.
            Facilitate regulatory compliance by maintaining a comprehensive history of customer
            communication.`,
            img_url: Images.com

        },
        {
            title: "Multi-Channel Support Teams ",
            description: `Support teams managing inquiries from diverse channels (email, chat, phone) can benefit from a
            single interface for enhanced efficiency.
            Provide agents with quick access to the entire customer interaction history for more informed
            responses.`,
            img_url: Images.multi

        },
        {
            title: "Human Resources",
            description: `Centralize employee inquiries, feedback, and requests from various channels.
            Improve HR efficiency in handling employee-related communication and support.`,
            img_url: Images.hum

        },
        {
            title: "Supply Chain Management",
            description: `Coordinate communication related to orders, shipments, and inventory across different channels.
            Enhance efficiency in managing supply chain processes through unified communication.`,
            img_url: Images.sup

        },
        {
            title: "Quality Assurance",
            description: `Centralize customer feedback & quality-related inquiries from various channels. Enhance efficiency of quality assurance teams in addressing customer concerns processes.`,
            img_url: Images.qua

        },
    ];


    return (
        <div className="slider-container">
            <MainContainer style={{ padding: "0px" }}>
                <Heading level={1} style={{ textAlign: 'center' }}>
                    Use Cases
                </Heading>
                <Slider {...settings}>
                    {weHelpItems.map((item) => {
                        return (
                            <CardWrapper>
                                <img src={item.img_url} alt="" />
                                <TextWrapper>
                                    <h2>{item.title}</h2>
                                    <p>{item.description}</p>
                                </TextWrapper>
                            </CardWrapper>
                        )
                    })}

                </Slider>
            </MainContainer>
        </div>
    );
}

export default Carousel;


const CardWrapper = styled.div`
color: ${theme.colors.lightgrey};
  text-align: left;
  width: 100%;
  position: relative;
  border-radius: 20px;
  min-height: 164px;
  box-shadow: ${theme.colors.primary} 0px 0px 26px -15px;

  @media (min-width: 1020px) {
    max-width: 421px;
  }

    h2 {
        font-size: ${theme.fontSizes.heading3};
        color: ${theme.colors.secondary};
    }
    p {
        font-size: ${theme.fontSizes.paragraph6};
     margin-top: 5px;
    }

    img {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    
`;

const TextWrapper = styled.div`
padding: 15px 2rem;
`;

const ArrowRight = styled.div`
&::before,
&::after {
  content: none !important; /* Set content to 'none' to hide */
}

@media (min-width: 748px) {
    content: '' !important;
    display: block !important;
    width: 0;
    height: 0;
    border-top: 10px solid transparent !important;
    border-bottom: 10px solid transparent !important;
    border-left: 10px solid ${theme.colors.primary} !important;
    right: 24px !important;
  }
    
`;

const ArrowLeft = styled.div`
&::before,
&::after {
  content: none !important; /* Set content to 'none' to hide */
}
@media (min-width: 748px){
    content: '' !important;
    display: block !important;
    width: 0 !important;
    height: 0 !important;
    border-top: 10px solid transparent !important;
    border-bottom: 10px solid transparent !important;
    border-right: 10px solid ${theme.colors.primary} !important;
    left: 42px !important;
}

`;