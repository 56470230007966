import React from 'react';
import { styled } from 'styled-components';
import Heading from './Heading';
import Paragraph from './Paragraph';
import { MainContainer } from '../../assets/styles/theme/styles';
import theme from '../../assets/styles/theme/theme';

export default function FlexCardWrapper({ heading, para, cardData }) {
  return (
    <MainContainer>
      <HeroItem>
        <Heading level={1}>{heading}</Heading>
        {para && <Paragraph level={1}>{para}</Paragraph>}
      </HeroItem>
      <WeHelpContainer>
        {cardData.map((card, index) => (
          <WeHelpItem key={index}>
            <StyledList style={{ textAlign: 'left' }}>
              {card.items.map((item, itemIndex) => (
                <> <StyledListItem key={itemIndex}>
                  <div style={{paddingRight:'20px',fontSize:'20px'}}>•</div>
                  <div>{item}</div>
                  </StyledListItem></>
              ))}
            </StyledList>
          </WeHelpItem>
        ))}
      </WeHelpContainer>
    </MainContainer>
  );
}

const WeHelpContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  
  @media (min-width: 1020px) {
    flex-wrap: unset;

  }
`;

const WeHelpItem = styled.div`
  color: ${theme.colors.lightgrey};
  text-align: center;
  width: 100%;
  margin: 10px;
  position: relative;
  border-radius: 20px;
  padding: 1rem 2rem;

  box-shadow: ${theme.colors.primary} 0px 0px 26px -15px;
  background-color: white;

  @media (min-width: 1020px) {
    max-width: 400px;
  }
`;

const HeroItem = styled.div`
  margin-bottom: 3rem;
`;

const StyledList = styled.div`
  padding: 0;
  list-style: inside;
`;

const StyledListItem = styled.div`
  margin-bottom: 8px;
display: flex;

`;

const Bullet = styled.div`
display: inline;
  margin-right: 8px; /* Adjust as needed for spacing between bullet and text */
`;



