import styled, { keyframes } from "styled-components";

// const slideInLeft = keyframes`
//   0%, 100% {
//     transform: scale(1);
//   }
//   50% {
//     transform: scale(1.1);
//   }
// `;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// const slideInRight = keyframes`
// from {
//   transform: translateX(100%);
//   opacity: 0;
// }
// to {
//   transform: translateX(0);
//   opacity: 1;
// }
// `;

export { fadeIn };
