import React from "react";
import Heading from "./Heading";
import styled from "styled-components";
import { MainContainer } from "../../assets/styles/theme/styles";
import Paragraph from "./Paragraph";
import theme from "../../assets/styles/theme/theme";

const HeroItem = styled.div`
  margin-bottom: 3rem;
`;

const WeHelpContainer = styled.div`
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  // gap: 2rem;

  @media (min-width: 720px) {
    //   text-align: left;
    border: 2px solid ${theme.colors.primary};
    border-radius: 50px;
    padding: 2rem;
  }

  @media (max-width: 720px) {
    //   text-align: left;
    border: 2px solid ${theme.colors.primary};
    border-radius: 50px;
    padding: 2rem;
  }
`;

const WeHelpItem = styled.div`
  color: ${theme.colors.lightgrey};
  text-align: center;
  margin: 1rem;
  position: relative;
  padding: 1.2rem;

  width: 232px;

  &:not(:last-child)::before {
    content: "";
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: ${theme.colors.primary};
    right: -18px; /* Line on the right for all cards except the last one */
  }

  @media (max-width: 1200px) {
    padding: 0.6rem;
    margin: .5rem;


    &:before {
      display: none;
    }
  }
  @media (min-width: 1020px) {
    /* text-align: left; */
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1100px;
  margin: 0px auto;

  &:not(:last-child) {
    border-bottom: 2px solid ${theme.colors.primary};
  }

  @media (max-width: 578px){
    &:not(:last-child) {
      border-bottom: none;
    }
  }
`;

export default function GridTextContainer({ heading, helpContent, para }) {
  const helpContent1 = [
    {
      // title: "Unified view of Customer Conversation histsory",
      description: `Unified view of Customer Conversation history`,
    },
    {
      // title: "Cross-Platform Compatibility of both Messaging and Voice",
      description: `Cross-Platform Compatibility of both Messaging and Voice.`,
    },
    {
      // title: "Live conversation through Chat or Voice",
      description: `Live conversation through Chat or Voice`,
    },
    {
      // title: "Prioritizing Customer",
      description: `Effortless API integration with diverse messaging platforms.`,
    },
  ];


  const helpContent2 = [
    {
      // title: "Disposition with summary",
      description: `Disposition with summary`,
    },
    {
      // title: "Chat Bot integration with history",
      description: `Chat Bot integration with history`,
    },
    {
      // title: "Transfer of Conversations",
      description: `Transfer of Conversations`,
    },
    {
      // title: "Disposition of calls",
      description: `Disposition of calls/ chat`,
    },
  ];

  const helpContent3 = [
    {
      // title: "Rich Media depending on channel",
      description: `Rich Media depending on channel`,
    },
    {
      // title: "Predefined Template Messages",
      description: `Predefined Template Messages`,
    },
    {
      // title: "Analytics and Insights",
      description: `Analytics and Insights`,
    },
    {
      // title: "Analytics and Insights",
      description: `  Prioritizing Customer
      `,
    },
  ];

  return (
    <>
      <MainContainer>
        <HeroItem>
          <Heading level={1} style={{ textAlign: "center" }}>
            {heading}
          </Heading>
          {para && <Paragraph level={1}>{para}</Paragraph>}
        </HeroItem>
        <WeHelpContainer>
          <CardContainer>
            {helpContent1.map((content, index) => (
              <WeHelpItem key={index}>
                <Heading level={3} style={{ textAlign: "center" }}>
                  {content.title}
                </Heading>
                <Paragraph level={2} style={{ marginBottom: "0px" }}>
                  {content.description}
                </Paragraph>
              </WeHelpItem>
            ))}
          </CardContainer>
          <CardContainer>
            {helpContent2.map((content, index) => (
              <WeHelpItem key={index}>
                <Heading level={3} style={{ textAlign: "center" }}>
                  {content.title}
                </Heading>
                <Paragraph level={2} style={{ marginBottom: "0px" }}>
                  {content.description}
                </Paragraph>
              </WeHelpItem>
            ))}
          </CardContainer>
          <CardContainer>
            {helpContent3.map((content, index) => (
              <WeHelpItem key={index}>
                <Heading level={3} style={{ textAlign: "center" }}>
                  {content.title}
                </Heading>
                <Paragraph level={2} style={{ marginBottom: "0px" }}>
                  {content.description}
                </Paragraph>
              </WeHelpItem>
            ))}
          </CardContainer>
        </WeHelpContainer>
      </MainContainer>
    </>
  );
}
