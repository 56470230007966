import React from 'react'
import { Link } from "react-router-dom";
import styled from 'styled-components';
import Images from './Images';

export default function BrandLogo({ link }) {
    return (
        <NavbarLogo to={"/"}>
            <img src={Images.company_logo} />
        </NavbarLogo>
    )
}


const NavbarLogo = styled(Link)`
 img {
    width: 130px;
    height: auto;
 }

  @media screen and (max-width: 800px) {
    img {
        width: 110px;
     }
  }
`;