import React, { useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Button } from "react-bootstrap";

const Payment = (props) => {


    // onSubmit handler of the form, use the ccavenue module to create a transaction object with the payment details.
    const transaction = {
        // order_id: commonManagerObj.generateRandomOrderId(),
        // order_id: props.userDetails.uuid,
        currency: "INR",
        amount: props.userDetails.total,
        redirect_url: `https://1inbox.ai/ccpayment/ccavResponseHandler`,
        cancel_url: `https://1inbox.ai/ccpayment/ccavResponseHandler`,
        language: "EN",       // firstname: props.userDetails.name,
        billing_name: props.userDetails.name,
        billing_address: props.userDetails.companyName,
        billing_city: props.userDetails.companyName,
        billing_state: props.userDetails.companyName,
        billing_zip: props.userDetails.companyName,
        billing_country: "india",
        billing_tel: props.userDetails.mobileNumber,
        billing_email: props.userDetails.email,
        delivery_name: props.userDetails.name,
        delivery_address: props.userDetails.companyName,
        delivery_city: props.userDetails.companyName,
        delivery_state: props.userDetails.companyName,
        billing_city: props.userDetails.companyName,
        delivery_zip: props.userDetails.companyName,
        delivery_country: "IN",
        delivery_tel: props.userDetails.mobileNumber,
        integration_type: 'iframe_normal'
    };

    useEffect(() => {
        openPayModal()
    }, [])

    //   Add the checksum to the transaction object.
    //   Use the ccavenue module to create the payment form and submit it to the CC Avenue server.
    const openPayModal = async () => {
        const form = document.createElement("form");
        form.action =
            `/ccpayment/ccavRequestHandler`;
        form.method = "POST";

        const uuid = await props.handlePrePayClick();

        console.log("uuid =>", uuid);
        if (uuid) {
            transaction["order_id"] = uuid;
            Object.keys(transaction).forEach((key) => {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = transaction[key];
                form.appendChild(input);
            });

            console.log('form params====>', form);
            // document.body.appendChild(form);
            // form.submit();
            // Open the payment form in a popup window

            // const windowOptions =
            //     "height=800,width=600,left=150,top=150,location=1,toolbar=1,resizable=1,scrollbars=1";
            // window.open("", "CCavenuePayment", windowOptions);
            // form.target = "CCavenuePayment";
            document.body.appendChild(form);
            form.submit();

        }


    };

    return (null
        // <Button
        //     className="btn btn-primary btn-md"
        //     style={{
        //         alignContent: "stretch",
        //         backgroundColor: "#5E6FF9",
        //         borderredius: "20px",
        //         width: "250px",
        //         display: "grid",
        //         marginBottom: "4rem",
        //         marginLeft: "20px",
        //         justifyContent: "center",
        //         visibility: props.isLoading ? "hidden" : "visible",
        //         marginTop: "1rem",
        //     }}
        //     onClick={openPayModal}
        //     disabled={props.disabled}
        // >
        //     Proceed to payment
        // </Button>
    );
};

export default Payment;
