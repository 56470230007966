
import React from "react";
import styled from "styled-components";
import Images from "../../utils/Images";
import { LuIndianRupee } from "react-icons/lu";
import { MainContainer } from "../../../assets/styles/theme/styles";



const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead > th {
  font-size: 25px;
  }

  tbody > tr > td {
    font-size: 20px;
  }

  th, td {
    // border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  th {
    padding: 10px 10px 20px 10px;
    background-color: white;
    position: sticky;
    top: 0; 
  }
  tr {
    background-color: #edf0ff;
  }

  tr:nth-child(even) {
    background-color: white;
  }

  @media (max-width: 768px) {

  thead > th {
  font-size: 17px;
  }

  tbody > tr > td {
    font-size: 17px;
  }

    th, td {
      width: 100%;
      box-sizing: border-box;
    }

    th {
      text-align: center;
    }
  }
  .icon-cell {
    width: auto; 
    text-align: -webkit-center !important;
  }
`;

const Title = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: lighter;
  
   @media (max-width: 768px) {
   font-size: 25px;
   font-weight: bold;
  }
`;


const PricingTable = () => {

  return (
    <MainContainer>
      <Title>Plan Features & Comparisons</Title>
      <StyledTable>
        <thead>
          <th style={{ fontFamily: "poppins", fontWeight: "lighter" }}>
            Pricing
          </th>
          <th>
            <div style={{ display: "flex", flexDirection: "column", fontFamily: "poppins", fontWeight: "lighter", alignItems: "center" }}>
              Connect
              <p style={{ color: "#5e6ff9", display: "flex", alignItems: "center" }}> <LuIndianRupee />599 </p><span style={{ fontWeight: "100", fontSize: "15px" }}>per month</span>
            </div>

          </th>
          <th>
            <div style={{ display: "flex", flexDirection: "column", fontFamily: "poppins", fontWeight: "lighter", alignItems: "center" }}>
              Engage
              <p style={{ color: "#5e6ff9", display: "flex", alignItems: "center" }}> <LuIndianRupee />1,499 </p><span style={{ fontWeight: "100", fontSize: "15px" }}>per month</span>
            </div>
          </th>

          <th>
            <div style={{ display: "flex", flexDirection: "column", fontFamily: "poppins", fontWeight: "lighter", alignItems: "center" }}>
              Enhance
              <p style={{ color: "#5e6ff9", display: "flex", alignItems: "center" }}> <LuIndianRupee />20,990 </p><span style={{ fontWeight: "100", fontSize: "15px" }}>per month</span>
            </div>
          </th>
          {/* <th>
          <div style={{display:"flex", flexDirection:"column",fontFamily:"poppins",fontSize:"20px",fontWeight:"lighter",alignItems:"center"}}>
              Enterprises
              <p style={{color:"#5e6ff9",display:"flex",alignItems: "center",fontSize:"30px"}}> <LuIndianRupee />4,999 </p><span style={{fontWeight:"100",fontSize:"15px"}}>per month</span>
              </div>
              </th> */}
        </thead>
        <tbody>
          <tr>
            <td>Web Chat Automated Welcome Chat</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Chat priority feature</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Chat transfer to available agent feature</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Chat hold feature</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Chat break feature</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Chat queue feature</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Chat summary feature</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Chat dispose feature</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Whatsapp Template message feature</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Whatsapp sent message from 1inbox</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Documents, Pdf, image sharing using whatsapp, RCS, webchat</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <td>RCS Template message feature</td>
          <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
          <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          <tr>
            <td>RCS Sent message from 1inbox</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Auto, preview, broadcast, manual, inbound Calling mode</td>
            <td className="icon-cell"><img src={Images.cross} alt="cross" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Call Transfer for Agent, phone, flow, and Team feature</td>
            <td className="icon-cell"><img src={Images.cross} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Click to call from chat feature</td>
            <td className="icon-cell"><img src={Images.cross} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Call entry with recording and call summary, dispose tag will reflected in chat feature</td>
            <td className="icon-cell"><img src={Images.cross} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Call reports feature</td>
            <td className="icon-cell"><img src={Images.cross} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>SMS Template message feature</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Smileys messages feature</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Multi level chat disposition feature</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>

          <tr>
            <td>Profile edit</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
          <tr>
            <td>Customer Satisfaction feature</td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /> </td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
            <td className="icon-cell"><img src={Images.check} alt="check" style={{ height: '20px', width: "20px" }} /></td>
          </tr>
        </tbody>
      </StyledTable>


    </MainContainer>
  );
};

export default PricingTable;
