import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MainContainer } from "../assets/styles/theme/styles";
import { BsCaretDownFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Lic.module.css";
import { LuIndianRupee } from "react-icons/lu";

import AlertComponent from "./AlertComponent";

const Licenses = () => {
  const [showAlert, setShowAlert] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const licensesArr = Array.from({ length: 10 }, (_, index) => index + 1);
  const stateFromPreviousPage = location.state || {};
  const platformFee = 1;

  const [isAnnual, setIsAnnual] = useState(
    stateFromPreviousPage.billingFrequency === "Monthly" ? false : true
  );
  const [selectedLicense, setSelectedLicense] = useState(
    stateFromPreviousPage.selectedUser || 1
  );
  const [enterNumber, setEnterNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("india");
  const [selectedNumber, setSelectedNumber] = useState('')
  const [availableNumbers, setAvailableNumbers] = useState([]);
  const [totalCost, setTotalCost] = useState(
    (stateFromPreviousPage.selectedUser || 1) *
      stateFromPreviousPage.selectedAmount || 0
  );
  const [productPrice, setProductPrice] = useState(
    stateFromPreviousPage.selectedAmount || 0
  );
  const [selectedNumberCost, setSelectedNumberCost] = useState(0);
  const [gst, setGst] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(
    stateFromPreviousPage.annualDiscount || 0
  );
  const [discountedPrice, setDiscountedPrice] = useState(0)

  useEffect(() => {
    let total = stateFromPreviousPage.plan === "Enhance" ? productPrice : selectedLicense * productPrice;
    if (stateFromPreviousPage && stateFromPreviousPage.plan === "Connect") {
      if (stateFromPreviousPage.selectedUser == 1) {
        total = platformFee;
        // setTotalCost(total)
      } else {
        total = total - productPrice;
        // setGst(total * 0.18)
        // setTotalCost((total + (total * 0.18)) + selectedNumberCost)
      }
    }
    setGst(total * 0.18);
    setTotalCost(total + (total * 0.18) + selectedNumberCost);

    // getAvailableNumbers();
    console.log(stateFromPreviousPage)
  }, [])


  // const getAvailableNumbers = () => {
  //   fetch(`https://portal.tubelightcommunications.com/super/inventory/v1/did/autoassigned-number?country_name=India&circle=&type=voice`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Something went wrong!");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setAvailableNumbers(data)
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // }

  const handleBillingTabChange = (e) => {
    let productPriceCopy =
      e.target.id === "annual"
        ? stateFromPreviousPage.annualPrice
        : stateFromPreviousPage.monthlyPrice;
    let productPriceFinal = stateFromPreviousPage.plan === "Enhance" ? productPriceCopy : selectedLicense * productPriceCopy;
    setIsAnnual(e.target.id === "monthly" ? false : true);
    setProductPrice(productPriceCopy);

    if (stateFromPreviousPage && stateFromPreviousPage.plan === "Connect") {
      let deductedPrice = productPriceFinal - productPriceCopy
      if(selectedLicense == 1) {
        deductedPrice = platformFee
        // setTotalCost(deductedPrice) // for Connect plan always deduct 1 license as it is free
        // setGst(0)
      } else if (e.target.id === "annual") {
        let discountNumber = deductedPrice * (discountPercent / 100);
        deductedPrice = deductedPrice - discountNumber;
        setDiscountedPrice(deductedPrice)
      }
      // else {
        setTotalCost(deductedPrice + (deductedPrice * 0.18)) // for Connect plan always deduct 1 license as it is free
        setGst(deductedPrice * 0.18)
      // }
    } else {
      if (e.target.id === "annual") {
        let discountNumber = productPriceFinal * (discountPercent / 100);
        productPriceFinal = productPriceFinal - discountNumber;
        setDiscountedPrice(productPriceFinal)
      }
      setTotalCost(productPriceFinal + (productPriceFinal * 0.18));
      setGst(productPriceFinal * 0.18);
    }
  };

  const handleSelectChange = (e) => {
    let productPriceFinal = e.target.value * productPrice;
    setSelectedLicense(e.target.value);

    if (stateFromPreviousPage && stateFromPreviousPage.plan === "Connect") {
      let deductedPrice = productPriceFinal - productPrice
      if (e.target.value == 1) {
        setDiscountedPrice(0)
        deductedPrice = platformFee
        // setTotalCost(deductedPrice) // for Connect plan always deduct 1 license as it is free
        // setGst(0)
      } else if (isAnnual) {
        let discountNumber = deductedPrice * (discountPercent / 100);
        deductedPrice = deductedPrice - discountNumber;
        setDiscountedPrice(deductedPrice)
      }
      // else {
        setTotalCost(deductedPrice + (deductedPrice * 0.18)) // for Connect plan always deduct 1 license as it is free
        setGst(deductedPrice * 0.18)
      // }
    } else {
      if (isAnnual) {
        let discountNumber = productPriceFinal * (discountPercent / 100);
        productPriceFinal = productPriceFinal - discountNumber;
        setDiscountedPrice(productPriceFinal)
      }
      setTotalCost(productPriceFinal + (productPriceFinal * 0.18));
      setGst(productPriceFinal * 0.18);
    }
  };

  const handleInputChange = (e) => {
    setEnterNumber(e.target.value);
    setSelectedNumber("");
    setSelectedNumberCost(0);
    setShowAlert(false);
  };


  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  function handleShowAlert(type, message) {
    setAlertType(type);
    setAlertMessage(message);
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }

  const handleContinueClick = () => {
    if (
      selectedNumber === "" &&
      document.querySelector('input[name="numberManage"]:checked').value === "bringYourOwn"
    ) {
      handleShowAlert('error', 'Please enter a number!');
      return;
    }

    // if (enterNumber.length < 10) {
    //   handleShowAlert('error', 'Please enter valid number!');
    //   return;
    // }
  

    navigate("/sign-up", {
      state: {
        license: selectedLicense,
        numbers: selectedNumber,
        validity: isAnnual ? 365 : 30,
        PPricingId: stateFromPreviousPage.id || 0, // static product and plan id
        credit: stateFromPreviousPage.selectedCredits * selectedLicense || 0,
        total: totalCost || 0,
      },
    });
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleNumberChange = (e) => {
    let numberDetail = availableNumbers[e.target.selectedIndex];
    console.log(numberDetail, e.target.selectedIndex);
    setSelectedNumber(e.target.value);
    setEnterNumber("");
    if (numberDetail) {
      setSelectedNumberCost(
        parseInt(numberDetail.customer_mrc || 0) +
          parseInt(numberDetail.customer_nrc || 0)
      );
      setTotalCost(
        selectedLicense * productPrice +
          parseInt(numberDetail.customer_mrc || 0) +
          parseInt(numberDetail.customer_nrc || 0)
      );
    }
  };

  return (
    <MainContainer>
      <HowWrapper>
        <HowHeading>How many licenses you need?</HowHeading>
        {/* <HowHeadings>
          Find pricing for all communication channels, products, numbers, and
          services.
        </HowHeadings> */}

        <DropdownWrapper>
          <SelectDropdown value={selectedLicense} onChange={handleSelectChange} disabled={stateFromPreviousPage.plan === "Enhance"}>
            <option value="" disabled>
              Choose
            </option>
            {licensesArr.map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </SelectDropdown>
          <CaretDownIcon />
        </DropdownWrapper>

        <div className={styles.con}>
          <div className={styles.left}>



            {/* <div className={styles.top}>
              <div className={styles.box}>
                <input style={{ height: '30px', width: '30px' }} type="radio" name="numberManage" />
                <h1>Buy Number</h1>
              </div>
              <div className="cards">
                <div className="mar">
                  <DropdownWrappers>
                    <SelectDropdowns
                      value={selectedCountry}
                      onChange={handleCountryChange}
                    >
                      <option value="" disabled>
                        Select Country
                      </option>
                      <option value="india">India</option>
                    </SelectDropdowns>
                    <CaretDownIcon />
                  </DropdownWrappers>
                </div>
                <div className="mar">
                  <DropdownWrappers>
                    <SelectDropdowns
                      value={selectedNumber}
                      onChange={handleNumberChange}
                    >
                      <option value="" disabled>
                        Select Number
                      </option>
                      {availableNumbers.map((num, i) => <option key={i} value={num.VmNumbers}>
                        {num.VmNumbers}
                      </option>)}
                    </SelectDropdowns>
                    <CaretDownIcon />
                  </DropdownWrappers>
                </div>
              </div>
            </div> */}

            <div className={styles.bottom} style={{display: "flex", flexDirection:"column", justifyContent:"space-between"}} >
              <div>
              <div className={styles.box}>
                <input style={{ height: '30px', width: '30px' }} type="radio" name="numberManage" checked={true} onChange={() => { }} />
                <h1>Bring Your Own Number For WhatsApp Business</h1>
              </div>

              <DropdownWrappers>
                <div className={styles.inp}>
                  
                <input
  style={{ width: "100%" }}
  type="text"
  required
  placeholder="Enter your number"
  minLength={10}
  maxLength={14}
  value={enterNumber}
  onChange={handleInputChange}
  onKeyPress={(e) => {
    const validKey = /[0-9\b]/.test(e.key);
    if (!validKey) {
      e.preventDefault();
    }
  }}
/>


                  {showAlert && (
                    <AlertComponent type={alertType} message={alertMessage} />
                  )}

                  {/* <CaretDownIcon /> */}
                </div>
              </DropdownWrappers>
              </div>
              <NoteWrapper>
              <hr style={{marginBottom:"1rem"}}/>

                    <labell>NOTE:</labell>
                  <ol>
                <li> The number entered should not have an active WhatsApp account.   </li>
                <li> If it is an existing WhatsApp Business number then please disable the service from your existing service provider and then enter the number.</li>
                <li> If you want a new number kindly {""}
                   <a href="mailto:helpdesk@tubelightcommunications.com?subject=Required new number for WhatsApp" target="_blank" style={{textDecoration:"underline", color: "blue"}}>
                   contact support.
                   </a>
              </li>
                </ol>
              </NoteWrapper>
            </div>
            {/* <Button
              onClick={handleContinueClick}
              style={{
                backgroundColor: "rgb(58,120,231)",
                color: "white",
                width: "100px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                paddingLeft: "80px",
                fontSize: "15px",
                paddingRight: "80px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              Continue
            </Button> */}
          </div>

          <div className={styles.right}>
            <h1 style={{ color: "#477af6" }}>
              {stateFromPreviousPage.plan || ""} Plan
            </h1>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                color: "#477af6",
              }}
            >
              <LuIndianRupee style={{ color: "#477af6" }} />
              {productPrice}
            </span>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "50px",
                boxShadow:
                  "0 0 5px rgba(255, 255, 255, 0.5) inset, 0 0 10px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Button
                id="monthly"
                onClick={handleBillingTabChange}
                style={{
                  backgroundColor: !isAnnual ? "#477af6" : "#ffffff",
                  color: !isAnnual ? "#ffffff" : "#333333",
                  borderRadius: "50px 0 0 50px",
                  cursor: "pointer",
                  outline: "none",
                  border: "none",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  // border: "2px solid rgb(184,184,184)",
                  fontWeight: "bold",
                }}
              >
                Monthly
              </Button>
              <Button
                id="annual"
                onClick={handleBillingTabChange}
                style={{
                  backgroundColor: isAnnual ? "#477af6" : "#ffffff",
                  color: isAnnual ? "#ffffff" : "#333333",
                  borderRadius: "0 50px 50px 0",
                  cursor: "pointer",
                  outline: "none",
                  border: "none",
                  fontSize: "1rem",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  // border: "2px solid rgb(184,184,184)",
                  fontWeight: "bold",
                }}
              >
                Annually
              </Button>
            </div>

            {/* <Prices/> */}

            <div style={{ padding: "2rem", width: "90%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <h2 style={{ fontSize: "20px" }}>
                  {stateFromPreviousPage.plan === "Enhance" ? `${selectedLicense} license` : 
                  `${selectedLicense} license X ${productPrice} X 1` }
                </h2>
                <h3 style={{ color: "rgb(109,143,226)" }}>
                  {stateFromPreviousPage.plan === "Enhance" ? productPrice : productPrice * selectedLicense}
                </h3>
              </div>
              {selectedNumber && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <h2 style={{ fontSize: "20px" }}>1 X Number X 1</h2>
                  <h3 style={{ color: 'rgb(109,143,226)' }}>{selectedNumberCost || 0}</h3>
                </div>)}
              {stateFromPreviousPage.plan === "Connect" &&
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <h2 style={{ fontSize: "20px" }}>1 Free License</h2>
                  <h3 style={{ color: 'rgb(109,143,226)' }}>-{productPrice || 0}</h3>
                </div>}
                
                {isAnnual &&
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <h2 style={{ fontSize: "20px" }}>Discount</h2>
                  <h3 style={{ color: 'rgb(109,143,226)' }}>{discountPercent}%</h3>
                </div>}
                {isAnnual && <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <h2 style={{ fontSize: "20px" }}>Discounted Price</h2>
                  <h3 style={{ color: 'rgb(109,143,226)' }}>{discountedPrice}</h3>
                </div>}
                {stateFromPreviousPage.plan === "Connect" && selectedLicense == 1 &&
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <h2 style={{ fontSize: "20px" }}>Platform Fee</h2>
                  <h3 style={{ color: 'rgb(109,143,226)' }}>{platformFee}</h3>
                </div>}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <h2 style={{ fontSize: "20px" }}>GST</h2>
                <h3 style={{ color: "rgb(109,143,226)" }}>
                  {gst.toFixed(2) || 0}
                </h3>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <h2 style={{ fontSize: "20px" }}>Annual saving</h2>
                <h3 style={{ color: 'rgb(109,143,226)' }}>-250</h3>
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* <h2 style={{ fontSize: "20px" }}>Apply Coupon</h2>
                <input
                  style={{
                    paddingRight: "0",
                    marginLeft: "auto",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    height: "30px",
                    width: "150px",
                    textAlign: "right",
                    paddingRight: "2rem",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  placeholder="Enter code"
                /> */}
              </div>
              <div
                style={{ borderBottom: "1px solid #ccc", marginTop: "30px" }}
              ></div>
            </div>

            <div
              style={{
                width: "90%",
                paddingLeft: "2rem",
                paddingRight: "2rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <h2 style={{ fontSize: "20px" }}>
                  <strong>Total Pay</strong>
                  <br />
                  <p style={{ fontSize: "10px", marginTop: "5px" }}>
                    (plus Taxes and Fees)
                  </p>
                </h2>
                <h3>{totalCost.toFixed(2)}</h3>
              </div>
            </div>
          </div>
        </div>

        <Button
          onClick={handleContinueClick}
          style={{
            backgroundColor: "#477af6",
            color: "white",
            width: "100px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
            paddingLeft: "80px",
            fontSize: "15px",
            paddingRight: "80px",
            paddingTop: "10px",
            paddingBottom: "10px",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Continue
        </Button>
      </HowWrapper>
    </MainContainer>
  );
};

const HowWrapper = styled.div``;

const HowHeading = styled.h1`
  font-size: 40px;
  font-weight: 400;
  rgb(149, 149, 149);
  padding-bottom: 15px;
`;

const HowHeadings = styled.h1`
  font-size: 20px;
  font-weight: 400;
  rgb(149, 149, 149);
  padding-top: 15px;
  padding-bottom: 15px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SelectDropdown = styled.select`
  /* Add your styling for the dropdown */
  background:none;
  padding: 10px;
  font-size: 16px;~
  background-color: white !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 80px;
  padding-left: 80px;
  @media (max-width: 811px) {
    width: 100%;
  }
`;

const CaretDownIcon = styled(BsCaretDownFill)`
  /* Add your styling for the caret-down icon */
  margin-left: -25px; /* Adjust the margin as needed */
  font-size: 16px;
  color: rgb(71, 122, 246);
`;

const SelectDropdowns = styled.select`
  /* Add your styling for the dropdown */
  padding: 10px;
  font-size: 16px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  // padding-right: 20rem;
  width: 100%;

  @media (max-width: 720px) {
    padding-right: unset;
  }
`;

const DropdownWrappers = styled.div`
  margin-bottom: 2rem;
  margin-top: 1rem;
  display: flex;
  padding-left: 4rem;
  padding-right: 2rem;
  align-items: center;
  // margin-left: 4rem;
  @media (max-width: 900px) {
    padding-right: 3rem;
  }
`;

const Button = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const NoteWrapper = styled.div`
 padding: 20px;
 font-size: 14px;
//  background-color:  #DDE6FD;
 
 li::before {
  content: "👉";
}
`;

export default Licenses;
