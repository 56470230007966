import React, { useState, useRef, useCallback, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";
import "bootstrap/dist/css/bootstrap.min.css";
import loogo from "../../assets/images/loogo.png";
import bottom from "../../assets/terms/bottom.png";
import topicon from "../../assets/terms/topicon.png";
import toparrow from "../../assets/terms/toparrow.png";
import TermsIn from "../../assets/terms/Terms-latest.pdf";
import Privacy from "../../assets/terms/Privacy-latest.pdf";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import styles from "./SignUp.module.css";
import { Loader } from "./Style";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import AlertComponent from "../AlertComponent";
import { encryptPayload } from "../../helpers/EncryptDecrypt";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import Payment from "../payment/Payment";
import { Link } from "react-router-dom";
import { apiToken, customerOnboard, sendOtp, verifiedEmail } from "../utils/dotenv";
import { toast } from "react-toastify";
import check from '../../assets/check-mark.png'
import { MdClose } from 'react-icons/md';



function ThankYouBox ({OnBoard, stateFromPreviousPage}) {
  return(
    <Modal
          id="modal4"
          size="md"
          backdrop="static"
          show={OnBoard}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
         <Modal.Body id="modal4" style={{justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  padding: "1.9rem",
  paddingTop: "1.3rem",
  gap:"3px"}}>
      {/* {messageError && ( */}
        <React.Fragment>
          <img src={check} alt="" />
          <p style={{ textAlign: "center", color: "green", fontSize: "21px" }}>
                  Account Registered !
                  </p>
           <p style={{ textAlign: "center" }}>
                  {JSON.stringify(stateFromPreviousPage) !== "{}" ? 'Username and password sent to email. Redirecting to Payment Page...' :
                    "Please wait we are redirecting you to Login page..."}
                </p>
          {/* <MinimalLoader /> */}
        </React.Fragment>
      {/* ) } */}
    </Modal.Body>
        </Modal>
  );
}


// otp box popup
function OtpBox({show, otp, setOtp,emailOtp,setEmailOtp,  setOtpBox, onHide, email, isEmailExist, handleClick ,phone, setPhone, isBtnDisabled, seconds}) {

  // const handleChange = (otp) => {
  //   setOtp(otp);

    
  // };

  function handleCloseBox({}) {
    setOtpBox(false);
    setPhone("+91");
  }
  return (

    <>
      <Modal
        className="my-modal1"
        id="modal1"
        size="lg"
        backdrop="static"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
      <Modal.Header style={{ backgroundImage: 'none', display: 'flex', justifyContent: 'flex-end' }}>
    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
    <button
      type="button"
      className="close"
      style={{ backgroundImage: 'none', outline: 'none' }}
      onClick={onHide}
    >
      <MdClose size={30} /> 
    </button>
  </Modal.Header>
        <Modal.Body>
          <ModalBody>
            <h2 style={{ textAlign: "center", fontSize: "20px", fontWeight: "500", marginTop: "0", marginBottom: "0.5rem", lineHeight: "1.2", fontSize: "calc(1.325rem + .9vw)" }}>OTP Verification</h2>
            <p style={{ fontFamily: "Poppins", textAlign: "center" }}>
              Please enter the one-Time Code to verify your account.
              <br />A one-Time code has been sent to {phone}
            </p>
            <br />

            <p> Otp to verify {phone}</p>
            <OtpInput
              className="otp_input"
              inputType="tel"
              value={otp}
              
              onChange={(value) => setOtp(value)}
              numInputs={4}
              separator={<span>&ensp;</span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                margin: "0.5rem",
                height: "50px",
                width: "50px",
                border: "none",
                borderBottom: "2px solid grey",
                outline: "none",
              }}

            />
            <br />


            <p>Otp to verify {email} </p>
            <OtpInput
              className="otp_input"
              inputType="tel"
              value={emailOtp}
              onChange={(value) => setEmailOtp(value)}
              numInputs={4}
              separator={<span>&ensp;</span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                margin: "0.5rem",
                height: "50px",
                width: "50px",
                border: "none",
                borderBottom: "2px solid grey",
                outline: "none",
              }}

            />

          </ModalBody>
        </Modal.Body>

        <Modal.Footer
          className="modal-footer"
          style={{
            "--bs-modal-footer-border-color": "white",
            "--bs-modal-footer-gap": "0",
            paddingBottom: "30px",
            fontFamily: "Poppins",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#5e6ff9",
                display: "flex",
                justifyContent: "center",
                borderredius: "20px",
                alignItems: "center",
                fontWeight: "bold",
                width: "100%",
                height: "7%",
              }}
              onClick={() => isEmailExist()}
              className="text-center"
              disabled={otp.length < 4 || emailOtp.length < 4}
            >
              Verify
            </Button>
            <div>


              <button
                style={{
                  margin: "5px",
                  color: "black",
                  fontWeight: "600",
                  textDecorationLine: "none",
                }}
                className="btn btn-link"
                onClick={handleClick}
                disabled={isBtnDisabled}
                >
                 Resend One-Time Code {isBtnDisabled && (seconds) }
              </button>

              


              <p
                onClick={handleCloseBox}
                style={{ fontSize: "small", cursor: "pointer", display: "flex", justifyContent: "center" }}
              >
                Entered a wrong Number?
              </p>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

    </>
  );
}



export default function SignUp(props) {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // working here
  const [customerId, setCustomerId] = useState();
  const [companyName, setCompanyName] = useState("");
  const [role, setRole] = useState(2);
  const [isChecked, setIsChecked] = useState(false);
  const [otpBox, setOtpBox] = useState(false);        // otp box here
  const [OtpPara, setOtpPara] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("in");
  const [privacy, setPrivacy] = useState("");
  const [emailExistsErr, setEmailExistsErr] = useState(false);
  const [OnBoard, setOnBoard] = useState(false);
  // const [otpError, setOtpError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [emailPrompt, setEmailPrompt] = useState("");
  const [passwordPrompt, setPasswordPrompt] = useState("");
  const [checked, setChecked] = useState(false);
  const [companyNamePrompt, setCompanyNamePrompt] = useState("");
  const [existingCompanyName, setExistingCompanyName] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [date, setDate] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  // const [showAlert, setShowAlert] = useState(true);
  // const [alertType, setAlertType] = useState("success");
  // const [alertMessage, setAlertMessage] = useState("Message");

  // const [showReCAPTCHA, setShowReCAPTCHA] = useState(false);
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [userName, setUserName] = useState("");
  const [tenantName, setTenantName] = useState("");

  const [emailOtp, setEmailOtp] = useState('');
  const [seconds, setSeconds] = useState(30);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  


  const location = useLocation();
  const stateFromPreviousPage = location.state || {};


  useEffect(() => {

    window.addEventListener('message', handleRedirectHome);

    return () => {
      window.removeEventListener("message", handleRedirectHome);
    }

  }, [])


  // timer for enable/ disable button
  useEffect(() => {
    let timer;

    if (isBtnDisabled) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(timer);
            setIsBtnDisabled(false);
            // Additional logic when the timer reaches 0
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isBtnDisabled]);

// Clear OTP state if otpBox is false
useEffect(() => {
  const clearOtp = () => {
    // Your logic to clear OTP state
    setOtp("");
    setEmailOtp("");
  };

  if (!otpBox) {
    clearOtp();
  }
}, [otpBox]);

  const handleRedirectHome = (e) => {
    if (e && e.data === 'paymentFailed') {
      // Redirect to the homepage or perform other actions
      window.location.href = '/';
    } else if (e && e.data === 'paymentSuccess') {
      // Redirect to the homepage or perform other actions
      window.location.href = 'https://portal.1inbox.ai/';
    }
  }

  const cleanInputBox = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("+91");
    setCustomerId("");
    setCompanyName("");
    setRole("");
    setIsChecked(false);
    setOtpPara("");
    setPassword("");
    setCountry("");
    setOnBoard("");
    setShowPassword("");
    setChecked("");
  };

  //alert component function
  function handleShowAlert(type, message) {
    setAlertType(type);
    setAlertMessage(message);
    setShowAlert(true);

    // const timeoutId = setTimeout(() => {
    //   setShowAlert(false);
    // }, 3000);
  
    // // Clear the timeout when component unmounts or when needed
    // return () => clearTimeout(timeoutId);
  }


  const validateForm = () => {
    if (firstname === "") {
      toast.error('Please enter your first name!', {
        position: 'top-center',
      });
      return false;
    }
  
    if (lastname === "") {
      toast.error('Please enter your last name!', {
        position: 'top-center',
      });
      return false;
    }
  
    if (phone === "") {
      toast.error('Please enter your phone number!', {
        position: 'top-center',
      });
      return false;
    }
  
    if (email === "") {
      toast.error('Please enter your email address!', {
        position: 'top-center',
      });
      return false;
    }
  
    if (companyName === "") {
      toast.error('Please enter your company name!', {
        position: 'top-center',
      });
      return false;
    }
  
    if (password === "") {
      toast.error('Please enter your password!', {
        position: 'top-center',
      });
      return false;
    }
  
    if (country === "in") {
      if (phone.length !== 12) {
        toast.error('Please enter a valid phone number for India.', {
          position: 'top-center',
        });
        return false;
      }
    } else {
      if (phone === "" || phone.length <= 5 || phone.length >= 21) {
        toast.error('Please enter a valid phone number.', {
          position: 'top-center',
        });
        return false;
      }
    }
  

    const phoneRegex =
      /\+?(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{6,21}$/;

    if (phone === "" || !phoneRegex.test(phone)) {
      // console.log(`number number: ${phone}`);
      handleShowAlert("error", "Please enter a valid number.");
      return false;
    }

    //password
    const validPassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.*()_&])[A-Za-z\d@.*()_&]{8,15}$/;

    if (password === "" || !validPassword.test(password)) {
      setPasswordPrompt(
        "The password must contain 8-15 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character from the set @ . _ ( * ) &"
      );
      return false;
    } else {
      setPasswordPrompt("");
    }

    return true;
  };

  const linkStyle = {
    color: "#5e6ff9",
    textDecoration: "none",
  };

  const eyeStyle = {
    height:'24px',
    margin: "8px auto",
    width: "26px",
    position: "absolute",
    right: "20px",
    top: "27px",
    color: "#808B96",
  };

  const redirectSignIN = () => {
    window.location.href = "https://portal.1inbox.ai/";
  };

  const redirectPaymentPage = () => {
    console.log(`Yes I am PaymentPage`);
    setShowPaymentPage(true)
  };

  const isApiWork = () => {
    const apiUrl = "https://geolocation-db.com/json/";

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
       
        return response.json();
      })
      .then((data) => {
        const ipAddress = data.IPv4;
        const date = new Date();

        // console.log("IP Address:", ipAddress);
        // console.log("Date:", date);

        setIpAddress(ipAddress);
        setDate(date);

        const requestBody = {
          ipAddress: ipAddress,
          date: date.toString(),
        };
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  };

  async function isEmailExist() {
    isApiWork();
  
    // onboard customer
    let requestBody = {
      newUser: true,
      tenantName: companyName.trim(),
      customerId: customerId,
      name: firstname.trim() + " " + lastname.trim(),
      password: password,
      email: email,
      mobileNumber: phone,
      // balance: stateFromPreviousPage.credit || 10,
      balance: 0,
      role: role,
      company: companyName,
      country: country,
      ipAddress: ipAddress,
      date: date.toString(),
      region: 1,
      otp: otp + emailOtp,
      currencyType: "inr"
      // paymentSuccess: false
    };
  
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: encryptPayload(requestBody),
      // redirect: "follow",
    };
  
    try {
      const response = await fetch(customerOnboard, requestOptions);

      const Data = await response.json();
      setUserName(Data.userName || "");
      setTenantName(Data.tenantName || "");

      if (response.status===200) {
        // for otp verify
        setOtpBox(false);
        toast.success('Account created successfully!', {
          position: 'top-center',
        });
        setOnBoard(true);
  
        setMessageError(true);
  
        if (JSON.stringify(stateFromPreviousPage) !== "{}") {
          // console.log(`inside if`);
          setTimeout(() => {
            redirectPaymentPage();
          }, 4000);
        } else {
          // console.log(`inside else`);
  
          setTimeout(() => {
            redirectSignIN();
          }, 4000);
        }
  
      } if(response.status===403){
        toast.error('Invalid OTP, Please enter correct OTP!', {
          position: 'top-center',
        });
      }
      if(response.status===409){
        toast.error(`${Data.message}`, {
          position: 'top-center',
        });
      }
      if(response.status===400){
        toast.error('Something went wrong!', {
          position: 'top-center',
        });
      }
     
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  }
  

  function validateCompanyName() {
    if (companyName === "") {
      return;
    }
    const companyNameRegex = /^(?=.*[A-Za-z])[A-Za-z0-9_,./()\s'&#+*-]+$/;
    if (!companyNameRegex.test(companyName)) {
      handleShowAlert(
        "error",
        `Company name field allowed characters include alphanumeric and these special characters: [_,./()'&#+*-].`
      );

      setExistingCompanyName(true);
      return;
    } else {
      // setEmailExistsErr(false);
      setExistingCompanyName(true);
    }

    fetch(`${process.env.REACT_APP_VALID_COMPANY_NAME}`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
        // Authorization: `Bearer ${companyapiToken}`,
      },
      body: encryptPayload({
        companyName,
      }),
    })
      .then((data) => {
        if (data.status === 409) {
          setExistingCompanyName(true);
          setCompanyNamePrompt("Company Name Already Exists");
          return;
        } else {
          setCompanyNamePrompt("");
          setExistingCompanyName(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const sendOtpRequest = useCallback(


    async (data) => {
      // console.log(`captcha data: ${data}`);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Token ${process.env.REACT_APP_OTP_SIGNUP_TOKEN}`);
      myHeaders.append("Content-Type", "application/json");

      var formdata = {
        phone_number: `+${phone}`,
        email: `${email}`,
        time: Date.now(),
      };

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ data: encryptPayload(formdata) }),
        redirect: "follow",
      };

      // console.log(`send otp formdata:  ${JSON.stringify(formdata)}`);

      try {
        const response = await fetch(process.env.REACT_APP_OTP_SIGNUP, requestOptions);
        //status code mila
        //  console.log(`status code------: ${JSON.stringify(response.status)}`);

        const Data = await response.json();
        //response milgaya.
        // console.log(`status code------: ${JSON.stringify(Data)}`);


        if (response.status === 200) {
          setSeconds(30)
          setIsBtnDisabled(true);
          setOtpBox(true); 
          toast.success('OTP sent to your mobile number and Email id.', {
            position: 'top-center',
          });     
          // handleShowAlert("info", `OTP sent to your mobile number and Email id`);
        
        }

        if (response.status === 400) {
          // recaptchaRef.current.reset();
          // handleShowAlert("info", `${Data.error}`);
          toast.error(`${Data.error}`, {
            position: 'top-center',
          });
          setIsButtonDisabled(true)
        }

        return {response,Data}

      } catch (error) {
        console.error("Error:", error);
        handleShowAlert("error", "An error occurred while sending OTP.");
      }
    },
    [phone, email]
  );

  function verifyEmail() {
    if (!email) {
      // Email is empty
      setEmailExistsErr(false); // Reset email exists error
      // setEmailError(true);
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      // Email is in an invalid format
      setEmailExistsErr(true); // Reset email exists error
      // setEmailError(true);
      setEmailPrompt("Invalid Email");
      return;
    } else {
      setEmailPrompt("");
      setEmailExistsErr(false);
    }

    // Email validation passed, make API call
    fetch(verifiedEmail, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
      },
      body: encryptPayload({
        email: email,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // if(data.status === 200) {

        // }
        if (data.status === 409) {
          setEmailExistsErr(true);
          setEmailPrompt("Email already Exists");
        } else {
          // setEmailPrompt("");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }

  const handleClick = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(false);
      sendOtpRequest();
    } else {
      setIsChecked(false);
    }
  };

  



  const handleVisibilityClick = () => {
    setShowPassword((prevState) => !prevState);
  };

  const generateUUId = async () => {
    let tData = {
      userName: userName || '',
      tenantName: tenantName || '',
      companyName,
      amount: stateFromPreviousPage.total ? (stateFromPreviousPage.total * (100/118)) : 0, // deduct gst
      // address: this.state.address,
      emailId: email,
      mobileNumber: phone,
      payMentGateway: 1,
      status: 0,
      validity: stateFromPreviousPage.validity,
      concurrentLogin: stateFromPreviousPage.license,
      ppricingId: stateFromPreviousPage.PPricingId,
      didNumbers: [stateFromPreviousPage.numbers],
      // paymentResponse : response && {...response}
    };

    let uuid = null;
    await fetch(process.env.REACT_APP_GENERATE_PAYMENT_UUID, {
      method: "POST",
      body: JSON.stringify(tData),
      headers: {
        "Content-Type": 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        if (data) {
          uuid = data.uuid || ''
          cleanInputBox();
        }
      })
      .catch(err => {
        console.log(err)
      })

    return uuid;

  }

  return (
    <>
      {showPaymentPage &&
        <Payment
          userDetails={{
            name: firstname + lastname,
            companyName,
            mobileNumber: phone,
            email: email,
            total: stateFromPreviousPage.total
          }}
          isLoading={isLoading}
          handlePrePayClick={generateUUId}
        />}

      {showAlert && <AlertComponent type={alertType} message={alertMessage}  />}
      <ThankYouBox OnBoard={OnBoard} stateFromPreviousPage={stateFromPreviousPage}/>
      <OtpBox
        show={otpBox}
        otp={otp}
        setOtp={setOtp}
        emailOtp={emailOtp}
        setEmailOtp={setEmailOtp}
        setOtpBox={setOtpBox}
        onHide={() => {
          setIsChecked(false);
          setOtpBox(false);

        }}
        phone={phone}
        email={email}
        isEmailExist={isEmailExist}
        handleClick={handleClick}
        setPhone={setPhone}
        isBtnDisabled={isBtnDisabled}
        seconds={seconds}
      />
      <div className={styles.signup_container}>
        <img className={styles["top-arrow"]} src={topicon} alt="" />
        <img className={styles["top-icon"]} src={toparrow} alt="" />
        <section className={styles["contact-flex-container"]}>
          <div className={styles["contact-left-image"]}>
            <Link to="/">
              <img src={loogo} alt="" />
            </Link>
            <div className={styles.text_wrapper}>
              <hgroup>
                <h3 style={{ fontWeight: "bold" }}>Free Text Credits</h3>
                <p>No credit card required.</p>
              </hgroup>
              <br></br>
              <hgroup>
                <h3 style={{ fontWeight: "bold" }}>
                  Programmable APIs and Tools
                </h3>
                <p>
                  SMS, WhatsApp, Voice, Verification,<br></br>
                  Numbers and AI powered chatbots.
                </p>
              </hgroup>
              <br></br>
              <hgroup>
                <h3 style={{ fontWeight: "bold" }}>
                  Quick and Easy Integration
                </h3>
                <p>Get started in minutes.</p>
              </hgroup>
            </div>
          </div>

          <div className={styles["contact-form"]}>
            <h1 className={styles["large-heading-1"]}>
              Get Started
              <br />
            </h1>

            <p className={styles["input-type"]}>
              Already have an account ?{" "}
              <a
                href="https://portal.1inbox.ai/"
                style={linkStyle}
              >
                <b>Login</b>
              </a>
            </p>
            <FormContainer
              style={{ marginTop: "1rem" }}
              autoComplete="off"
              onSubmit={handleClick}
              disabled={isClicked}
            >
              <div className={styles["input-wrapper"]}>
                <label htmlFor="firstname">First Name</label>
                <input
                maxLength={25}
                  type="text"
                  className={styles["input-field"]}
                  value={firstname}
                  autoComplete="off"
                  placeholder="Enter Firstname"
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className={styles["input-wrapper"]}>
                <label htmlFor="lastname">Last Name</label>
                <input
                                maxLength={25}

                  type="text"
                  autoComplete="off"
                  className={styles["input-field"]}
                  value={lastname}
                  placeholder="Enter Lastname"
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>

              <div className={styles["input-wrapper"]}>
                <label htmlFor="companyname">Company Name</label>

                <input
                  type="text"
                  className={styles["input-field"]}
                  value={companyName}
                  placeholder="Company Name"
                  onChange={(e) => setCompanyName(e.target.value)}
                  onBlur={validateCompanyName}
                  maxLength={50}
                  required
                />
                <span style={{ color: "red" }}>{companyNamePrompt}</span>
              </div>
              <div className={styles["input-wrapper"]}>
                <label htmlFor="mobile">Mobile</label>

                <PhoneInput
                  countryCodeEditable={false}
                  country={"in"}
                  enableSearch={true}
                  placeholder="Enter Number"
                  className={styles.country_phone}
                  value={phone}
                  onChange={(phone, country) => {
                    setPhone(phone);
                    // sendOtpRequest();
                    setCountry(country.countryCode);
                  }}
                  required
                />
              </div>
              <div
                style={{ gridColumn: "1/-1" }}
                className={styles["input-wrapper"]}
              >
                <label htmlFor="email">Email</label>

                <input
                  type="email"
                  className={styles["input-field"]}
                  value={email}
                  placeholder="Enter Email Address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailPrompt("");
                  }}
                  onBlur={verifyEmail}
                  autoComplete="off"
                  required
                />
                <span style={{ color: "red" }}>{emailPrompt}</span>
              </div>
              <div
                style={{
                  gridColumn: "1/-1",
                  cursor: "pointer",
                  position: "relative",
                }}
                className={styles["input-wrapper"]}
              >
                <label htmlFor="password">
                  Password
                  <span style={{ fontSize: "12px", fontWeight: "lighter" }}>
                    {" "}
                    (Between 8-15 characters)
                  </span>{" "}
                </label>

                <input
                  type={showPassword ? "text" : "password"}
                  className={styles["input-field"]}
                  value={password}
                  placeholder="Enter Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordPrompt("");
                  }}
                  maxLength={15}
                  required
                />
                <span style={{ color: "red" }}>{passwordPrompt}</span>
                {/* {isValid ? 'Password is valid.' : 'Password is invalid.'} */}
                <span
                  className="material-symbols-outlined"
                  onClick={handleVisibilityClick}
                  style={{
                    padding: "0px",
                    marginLeft: "0px",
                    marginRight: "10px",
                  }}
                >
                  {showPassword ? (
                    <BsEye style={eyeStyle} />
                  ) : (
                    <BsEyeSlash style={eyeStyle} />
                  )}
                </span>
              </div>

              <div className={styles.checkbox} type="checkbox">
                <div style={{display:"flex", alignItems:"center", marginBottom: "5px"}}>
                <input
                  checked={isChecked}
                  onChange={() => {
                    setIsChecked(!isChecked);
                    if (!validateForm()) {
                      setIsChecked(false);
                    } 
                  }}
                  type="checkbox"
                />{" "}
                <span style={{ fontSize: "14px" }}>
                  I agree to all the{" "}
                  <a
                    href={`${country === "in" ? TermsIn : TermsIn}`}
                    target="_blank"
                    style={linkStyle}
                  >
                    Terms of Services
                  </a>{" "}
                  and{" "}
                  <a
                    href={Privacy}
                    target="_blank"
                    onClick={() => setPrivacy(true)}
                    style={linkStyle}
                  >
                    Privacy Policy
                  </a>
                </span>
                </div>
                <div className="checkbox" type="checkbox" style={{display:"flex", alignItems:"flex-start"}}>
                  {" "}
                  <input
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    type="checkbox"
                    style={{marginTop:"3px"}}
                  />{" "}
                  <span style={{ fontSize: "14px" }}>
                    I want to receive 1iNbox's news, trends, offers, or blogs by
                    email.
                  </span>
                </div>
                <br />
              </div>
              <div
                style={{
                  display: "grid",
                  flexDirection: "column",
                  width: "250px",
                  marginTop: "-2rem",
                }}
              >
                {/* {showReCAPTCHA && (
                  <Recaptcha
                    ref={(ref) => (recaptchaRef.current = ref)}
                    sitekey={recaptchaSiteKey}
                    onResolved={sendOtpRequest}
                  // onloadCallback={recaptchaLoaded}
                  />
                )} */}

                <Button
                  type="submit"
                  onClick={handleClick}
                  disabled={isBtnDisabled || !isChecked || (existingCompanyName || emailExistsErr)}
                  className="btn btn-primary btn-md"
                  style={{
                    alignContent: "stretch",
                    backgroundColor: "#5E6FF9",
                    borderredius: "20px",
                    width: "250px",
                    display: "grid",
                    marginBottom: "4rem",
                    marginLeft: "20px",
                    justifyContent: "center",
                    visibility: isLoading ? "hidden" : "visible",
                    marginTop: "1rem",
                  }}
                >
                  {JSON.stringify(stateFromPreviousPage) !== "{}" ? 'Send Otp ' : 'SignUp '}
                  {isBtnDisabled ? seconds: ""}
                </Button>

                {isLoading && <Loader />}
              </div>
            </FormContainer>
          </div>
        </section>
        <img className={styles.bottom_image} src={bottom} alt="" />
      </div>
    </>
  );
}

const ModalBody = styled.div`
  text-align: center;

  > div {
    justify-content: center;
  }
  p {
    font-size: 16px;
  }
  h2 {
    > div {
      justify-content: center;
    }
  }

  .otp_input input {
    width: 50px !important;
    height: 50px;

    @media (min-width: 768px) {
      width: 70px !important;
    }
  }
`;



const FormContainer = styled.form`
grid-gap: 1rem;
align-items: flex-start;
display: grid;
gap: 1rem;
grid-template-columns: repeat(auto-fit,minmax(200px,1fr));
justify-content: center;
margin-top: 3rem;

@media (min-width: 634px) {
   max-width: 550px;

}
`;
