import React from "react";
import Heading from "./Heading";
// import Paragraph from "./Paragraph";
// import Button from "./ Button";
import styled from "styled-components";
// import RedirectToPages from "./RedirectToPages";
import { MainContainer } from "../../assets/styles/theme/styles";
import theme from "../../assets/styles/theme/theme";

export default function TextButtonContainer({ heading, para, btn }) {
  return (
    <MainContainer>
      <div>
        <Heading level={1}>{heading}</Heading>
        <TextContainer>
          {para.map((item) => {
            return <p index={item}>{item}</p>;
          })}
        </TextContainer>

        {/* <br />
        <BtnsWrapper>
          {btn &&
            btn.map((item) => {
              return (
                <RedirectToPages href="contact-us">
                  <Button index={item} text={item} />
                </RedirectToPages>
              );
            })}
        </BtnsWrapper> */}
      </div>
    </MainContainer>
  );
}

const TextContainer = styled.div`
  text-align: center;

  p {
    color: ${theme.colors.lightgrey};
    font-size: ${theme.fontSizes.paragraph3};
    margin-top: 2rem;
  }

  @media (min-width: 1020px) {
    text-align: left;
  }
`;

const BtnsWrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;

  @media (min-width: 1020px) {
    justify-content: left;
  }
`;
