import React from "react";
import { HashLink } from "react-router-hash-link";

export default function RedirectToPages({ children, href, ...rest }) {
  return (
    <>
      <HashLink smooth to={`/${href}`} {...rest}>
        {children}
      </HashLink>
    </>
  );
}
