import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
	body {
		/* font-size: 18px; */
	}

	.widget_button {
		position:fixed;
		width:60px;
		height:60px;
		bottom:25px;
		right:25px;
		background-color: ${props => (props.domProps?.themeColor ? `${props.domProps.themeColor}` : "rgb(4, 12, 248)")};
		// background-color:rgb(4, 12, 248);
		font-weight: 500;
		font-size: 32px;
		vertical-align:center;
		color:#FFF;
		border-radius:50px;
		text-align:center;
		border-width: 0px;
		z-index: 999999;
		animation: glow 1s infinite alternate;
	}

	.widget_button svg {
		vertical-align: middle;
	}

	.notification-count {
		left: 0;
		width: 16px;
		height: 16px;
		background-color: red;
		border-radius: 50%;
		position: absolute;
		top: 0px;
		font-size: 10px;
		font-weight: bold;
	}

	@keyframes glow {
		from {
		  box-shadow: 0 0 10px -10px ${props => (props.domProps?.themeColor ? `${props.domProps.themeColor}` : "rgb(4, 12, 248)")};
		}
		to {
		  box-shadow: 0 0 10px 10px ${props => (props.domProps?.themeColor ? `${props.domProps.themeColor}` : "rgb(4, 12, 248)")};
		}
	  }

	#chat_box {
		z-index: 9999;
		display: none;
		position:fixed;
		bottom:100px;
		right:40px;
		/* padding: 10px 18px; */
		border-radius: 12px;
		width: 24rem;
		height: 524px;
		border: 1px solid rgb(192, 190, 190);
		box-shadow: 0px 0px 1px 0px #5A5A5A.;
		background-color: white;
		word-break: break-word;
	}

	.chat_header {
		padding: 8px;
		height: 56px;
		// font-size: 28px;
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
		font-weight: 500;
		background-color: ${props => (props.domProps?.themeColor ? `${props.domProps.themeColor}` : "rgb(4, 12, 248)")};
		// background-color:rgb(4, 12, 248);
		color: white;
	}

	.header_title {
		font-weight: 500;
		font-size: 18px;
	}

	.chat_header svg {
		vertical-align: middle;
	}

	#chat_body {
		// padding: 10px;
		/* position: absolute; */
		// line-height: 24px;
		// font-size: 16px;
		overflow: hidden;
		overflow-y: scroll;
		height: 418px;
		// height: 80%;
		width: 100%;
	}

	#left-message {
		// margin: 5px;
		padding: 5px 8px;
		/* left: 5px; */
		border-radius: 5px;
		color: black;
		background-color:rgb(211, 231, 237);
		max-width: 85%;
		word-break: break-all;
	}

	#right-message {
		// margin: 5px;
		padding: 5px 8px;
		/* right: 5px; */
		background-color: ${props => (props.domProps?.themeColor ? `${props.domProps.themeColor}` : "rgb(4, 12, 248)")};
		// background-color: rgb(4, 12, 248);
		color: white;
		border-radius: 5px;
		margin-left: auto;
		word-break: break-all;
		width: fit-content;
	}

	.reponse_parent {
		margin: 10px 12px;
	}

	.response_btn {
		margin-right: 5px;
		margin-bottom: 5px;
		padding: 8px 10px;
		border-radius: 10px;
		border-width: 0px;
		color: white;
		background-color: ${props => (props.domProps?.themeColor ? `${props.domProps.themeColor}` : "rgb(4, 12, 248)")};
		// background-color: rgb(4, 12, 248);
	}

	.chat_footer {
		position: absolute;
		bottom: 0px;
		padding: 5px;
		border-top: 1px solid rgb(192, 190, 190);
		width: -webkit-fill-available;
		background-color: white;
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
		display: flex;
		justify-content: space-between;
		height: 50px;
	}

	#customer_message {
		padding-left: 5px;
		border-width: 0px;
		width: 87%;
		height: 35px;
		outline: none !important;
		/* border:1px solid red; */
		/* box-shadow: 0 0 10px #719ECE; */
	}

	.chat_footer svg {
		vertical-align: middle;
	}

	/* 
	textarea {
		/* border-radius: 10px;
		border-color: rgb(192, 190, 190);
		border-bottom-width: 0px ;
		border-left-width: 0px ;
		border-right-width: 0px ; */
		/* width: 100%; 

	} */

	.send_btn {
		color: ${props => (props.domProps?.themeColor ? `${props.domProps.themeColor}` : "rgb(4, 12, 248)")};
		// color: rgb(4, 12, 248);
		font-size: 24px;
		float: right;
		border-width: 0px;
		background-color: transparent;
		cursor: pointer;
	}

	.chat-last-seen--right {
		animation: fadeIn 1s ease-in;
		display: flex;
		align-items: center;
		margin-left: auto;
		
		// margin-right: 30px;
	  }
	  
	  .chat-last-seen--left {
		animation: fadeIn 1s ease-in;
		display: flex;
		align-items: center;
		// margin-left: 30px;
		margin: 10px 12px
	  }
	  
	  .chat-bubble {
	  
		padding: 8px 10px;
		background: #EDF1F5;
		margin: 10px 12px;
		border-radius: 9px;
		position: relative;
		animation: fadeIn 1s ease-in;
		// word-break: break-all;
	  }
	  
	  .chat-bubble:after {
		content: "";
		position: absolute;
		top: 10px;
		width: 0;
		height: 0;
		border: 12px solid transparent;
		border-top: 0;
	  }
	  
	  .chat-bubble--left {
		/* padding-right: 14px;
		padding-bottom: 14px; */
	  }
	  
	  .chat-bubble--left:after {
		left: 0;
		border-right-color: #EDF1F5;
		border-left: 0;
		margin-left: -12px;
	  }
	  
	  
	  .chat-quick-reply--left {
		/* padding: 2px; */
		/* margin-left: 5px; */
		position: absolute;
		/* top: 0; */
		/* right: 0; */
		padding: 0;
		/* margin-top: -10px;
		margin-right: -10px; */
	  }
	  
	  .chat-quick-reply--right {
		/* padding: 2px; */
		/* margin-left: 5px; */
		/* margin-top: -10px;
		margin-left: -10px; */
		position: absolute;
		/* top: 0; */
		/* left: 0; */
		padding: 0;
	  }
	  
	  .chat-bubble--right {
		padding-left: 14px;
		padding-bottom: 14px;
	  }
	  
	  .chat-bubble--right span {
		display: flex;
		flex-direction: column;
	  }
	  
	  /* .chat-bubble--right span b {
		color: orange;
	  } */
	  
	  .chat-bubble--right:after {
		right: 0;
		border-left-color: ${props => (props.domProps?.themeColor ? `${props.domProps.themeColor}` : "rgb(4, 12, 248)")};
		border-right: 0;
		margin-right: -12px;
	  }
	  
	  .chat-bubble--right .material-symbols-outlined {
		/* padding: 2px; */
		/* margin-right: 5px; */
		color: white;
	  }
	  
	  @keyframes fadeIn {
		0% {
		  opacity: 0;
		}
		100% {
		  opacity: 1;
		}
	  }
	  .right-chat .chat-bubble {
		background: ${props => (props.domProps?.themeColor ? `${props.domProps.themeColor}` : "rgb(4, 12, 248)")};
		color: #fff;
	  }
	  
	  .chat-box-tray {
		background: #EDF1F5;
		display: flex;
		align-items: baseline;
		padding: 10px 15px;
		align-items: center;
		margin-top: 19px;
		bottom: 0;
	  }
	  .chat-box-tray input {
		margin: 0 10px;
		padding: 6px 2px;
	  }
	  .chat-box-tray i {
		color: grey;
		font-size: 30px;
		vertical-align: middle;
	  }
	  .chat-box-tray i:last-of-type {
		margin-left: 25px;
	  }

	  .document_preview {
		border: 1px dashed grey;
		border-radius: 14px;
		padding: 8px;
		width: 250px;
	  }

	  a {
		text-decoration: none;
		color: #212529
	  }
	  

	  /* Styles for mobile view */
	  @media (max-width: 768px) {
		  #chat_box {
			  width: 99.6%;
			  height: 100%;
			  top: 0;
			  left: 0;
			  border-radius: 0;
			  /* Add other styles as needed */
		  }
		  #chat_body {
			  height: 83%;
		  }
		  // .widget_button {
		  // 	visibility: hidden
		  // }
		  .chat_header {
			  border-radius: 0;
		  }
		  .chat_footer {
			//   height: 40px;
		  }  


`;

// const WidgetParentDiv = styled.div`
    
// `;

// const WidgetButton = styled.button`
    
// 	position:fixed;
// 	width:60px;
// 	height:60px;
// 	bottom:40px;
// 	right:40px;
// 	background-color:#0C9;
// 	color:#FFF;
// 	border-radius:50px;
// 	text-align:center;
// 	box-shadow: 2px 2px 3px #999;
// `;



// export {
//     WidgetParentDiv,
//     WidgetButton
// }