var uuid = require('uuid');


class WebSocketService {
    static instance = null;
    callbacks = {};
  
    // checkking if instance is created else create instance
    static getInstance() {
      if (!WebSocketService.instance) {
        WebSocketService.instance = new WebSocketService();
      }
      return WebSocketService.instance;
    }
  
    constructor() {
      this.socketRef = null;
      this.disConnect = this.disConnect.bind(this);
      this.reconnect = this.reconnect.bind(this);
    }
        
    connect(sender_id) {

      let contactId;
      const senderId = sender_id;

      if(sessionStorage.getItem("contactId")) {
        contactId = sessionStorage.getItem("contactId");
      }
      else {
        contactId = uuid.v4();
        try {
            sessionStorage.setItem("contactId", contactId);
          } catch(err) {
            console.log(err);
          }
      }
      

      if(senderId) {
        const path = `wss://${process.env.REACT_APP_DOMAIN}/ws/async/chat/${senderId}/${contactId}/`;
      
        this.socketRef = new WebSocket(path); //js default constructor returns socket object
        console.log("Socket Object => ", this.socketRef);
  
        this.socketRef.onopen = () => {
          console.log('WebSocket open');
        };
  
        // this.socketNewMessage(JSON.stringify({
        //   command: 'fetch_messages',
        // }));
  
        this.socketRef.onmessage = e => {
          console.log("onmessage" + e.data)
          this.socketNewMessage(e.data);
        };
  
        this.socketRef.onerror = e => {
          console.log(e.message);
        };
  
        this.socketRef.onclose = () => {
          console.log("WebSocket closed");
          this.reconnect();
        };
      }
      
  }

  reconnect() {
    this.connect();
    console.log("WebSocket reopened!");

  }

  disConnect() {
    // console.log("socket connections => ",this.socketRef.onclose);
    this.socketRef.close();
    // this.socketRef = null;

  }
  // method for fetching old messages list and also sending new message
  socketNewMessage(data) {
    const parsedData = JSON.parse(data);
    const command = parsedData.command;
    if (Object.keys(this.callbacks).length === 0) {
      return;
    }
    if (command === 'messages') {
      this.callbacks[command](parsedData.messages);
    }
    if (command === 'new_web_message') {
      this.callbacks[command](parsedData.message);
    }
    if (command === 'event') {
      this.callbacks[command](parsedData);
    }
  }

  fetchMessages(last_message_id="") {
    this.sendMessage({ 
      command: 'fetch_messages',
      message_count: 20,
      last_message_id
    }); //fetch list of old messages
  }

  newChatMessage(message) {
    // sends new message
    this.sendMessage({ 
      command: 'new_web_message', 
      phone_number: message.phone_number, 
      message: message.content, 
      channel: message.channel
    });
  }

  // user read events
  chatMessageEvent(message) {
    // sends user event
    this.sendMessage({ 
      command: 'event', 
      event: message.event,
      phone_number: message.phone_number || message.contact_id, 
      contact_id: message.contact_id,
      channel: message.channel,
      message: {
        sender: "user",
        message_id: message.message_id
      }
    });
  }

  // takes callback from chat application
  addCallbacks(messagesCallback, newMessageCallback, messageEvent) {
    this.callbacks['messages'] = messagesCallback;
    this.callbacks['new_web_message'] = newMessageCallback;
    this.callbacks['event'] = messageEvent;
  }
  
  sendMessage(data) {
    try {
      this.socketRef.send(JSON.stringify({ ...data }));
    }
    catch(err) {
      console.log(err.message);
    }  
  }

  // returns socket connection status
  state() {
    if(this.socketRef) {
      return this.socketRef.readyState;
    } else return 0;
  }

  // notification play
  musicPlay(){
    try {
          const audio = new Audio(`${process.env.REACT_APP_HOSTNAME}/sound/notify.mp3`);
          audio.muted = false;
          audio.play();
       
    } catch (error) {
      console.log(`[Notification] Play Error`, error);
    }
  }



}

const WebSocketInstance = WebSocketService.getInstance();
// console.log(WebSocketInstance.disConnect());
export default WebSocketInstance;