import React from "react";
import CommonDiv1 from "./pricing/components/CommonDiv1";
import Images from "../utils/Images";
import ImageTextSection from "../utils/ImageTextSection";
import GridTextContainer from "../utils/GridTextContainer";
import Benefits from "../utils/Benefits";
import Insights from "../utils/Insights";
import { Contact } from "../utils/Contact";
import "../../index.css";
import TextButtonContainer from "../utils/TextButtonContainer";
import FlexCardWrapper from "../utils/FlexCardWrapper";
import styled from "styled-components";
import Carousel from "../utils/Carousel";
export default function Home() {
  const text = [
    `Kahipay payment gateway is an API based solution
    used by businesses to accept payments online
    through different payment modes like Credit Cards,
    Debit Cards, UPI, Wallets, Netbanking, etc.`,
  ];

  const text2 = [
    `In the dynamic realm of customer communication, where seamless interactions are the cornerstone of success, 1iNbox emerges as a transformative solution, reshaping how brands engage with their customers. 

    `,
    `This all-encompassing platform is not merely an application; it signifies an evolution in cloud communication, designed to address the burgeoning challenges of fragmented conversations and disjointed interactions that often plague businesses. 

    `,
  ];

  const arrayData = [
    {
      cardDescription: `Accept payments using UPI QR via mobile app sda`,
    },
    {
      cardDescription: `Accept payments from your customers with simple API integration.`,
    },
    {
      cardDescription: `24x7 technical support or any other payment related queries.`,
    },
  ];
  const arrayData2 = [
    // `Plug-and-play APIs`,
    `Quick Onboarding`,
    // `PCI DSS compliance`,
    `24*7 technical support`,
  ];

  const cardIconStyle = {
    backgroundImage: 'url("../../assets/images/lin.png")',
    backgroundSize: "cover",
    backgroundColor: "red",
  };

  const paraTextBtnCon = [
    "Conversations between the client and brand across various channels like WhatsApp, voice calls, GoogleRCS, web chat, chatbots, etc. are orchestrated into a single window, facilitating both the customer and the brand.",
    "The brand agent can reply to the client not only in the channel in which the client has initiated the conversation but can also use SMS and emails to cross-channel any data required by the client within the same window.",
  ];
  const btnsTextContainer = ["Learn More", "Talk to Expert"];

  const cardData = [
    {
      items: [
        "Disposed History",
        "Number Masking",
        "Create Team",
        "Create Break for Agents",
      ],
    },
    {
      items: [
        "Multi Level Dispositions",
        "Max Concurrent Chats",
        "Max Hold Chats",
        "Transfer Message & Queue message Script Management",
      ],
    },
    {
      items: [
        "Set the log out transfer process for agents",
        "Add Templates",
        "Inbound Offer",
      ],
    },
  ];

  const cardData2 = [
    {
      items: [
        "Receive/Make Calls, Chats",
        "Can transfer conversations with summary",
        "Can dispose conversations with multi level disposition",
      ],
    },
    {
      items: [
        "Can set conversation priorities",
        "Can set conversation status",
        "Can send pre defined templates",
      ],
    },
    {
      items: [
        "Multichannel, Multimedia capabilities",
        "Conversation History",
        "Update Name against Number",
      ],
    },
  ];

  return (
    <>
    <LineImageWrapper>
      <CommonDiv1
        title={`Unified Omnichannel Conversations your Key To Customer Success.`}
        description={`Elevate customer experience effortlessly! Unify voice calls, messaging and rich media in one seamless interface. Experience a harmonious communication symphony that eliminates fragmented interaction ensuring a unified customer journey like never before!
        `}
        fgimage={Images.home}
        btns={["Contact Us"]}
      />
      
        <ImageTextSection
          heading="Empower your agents to offer superlative customer experience"
          text={text2}
          imageSrc={Images.home3}
          drn={"row-reverse"}
        />
      </LineImageWrapper>
      <TextButtonContainer
        heading="How it Works?"
        para={paraTextBtnCon}
        btn={btnsTextContainer}
      />

      <GridTextContainer heading="Key Features" />

      <Benefits
        heading="Benefits of 1iNbox"
        text={text}
        imageSrc={Images.home2}
      />
      <OverlapImageWrapper>
        <FlexCardWrapper heading={`Admin Capabilities`} cardData={cardData} />
        <FlexCardWrapper heading={`Agent Capabilities`} cardData={cardData2} />
      </OverlapImageWrapper>
      <Carousel/>
      <Insights imageSrc={Images.paySpeaker} drn={"row-reverse"} />
     

      <Contact 
        heading={`1iNbox <br /> Omnichannel Conversational Platform`}
        btntext="Get In Touch"
      />
    </>
  );
}




const OverlapImageWrapper = styled.div`
  background-image: url(${Images.line1});
  background-position: 1286px 250px;
  background-repeat: no-repeat;

  @media screen and (min-width: 1800px) {
    background-position: 1208px 248px;
  }

  @media screen and (min-width: 1700px) and (max-width: 1799px) {
    background-position: 1154px 300px;
  }

  @media screen and (min-width: 1600px) and (max-width: 1699px) {
    background-position: 1108px 276px;
  }

  @media screen and (min-width: 1500px) and (max-width: 1599px) {
    background-position: 893px 267px;
  }

  @media screen and (max-width: 1499px) {
    background-image: none;
  }
`;


const LineImageWrapper = styled.div`
    background-image: url(${Images.line2});
    background-position: -275px center;
    background-repeat: no-repeat;
    background-position-y: 280px;

    @media screen and (min-width: 1440px) and (max-width: 2000px) {
        background-position-y: 436px;
        background-position: -25px center;
    }

    @media screen and (min-width: 1306px) and (max-width: 1439px) {
        background-position-y: 427px;
        background-position: -92px center;
    }

    @media screen and (max-width: 1305px) {
        background-image: none;
    } 
`;