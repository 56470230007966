import React, { useState, useEffect, Component } from "react";
// import "../components/style.css";
// import axios from "axios";

export default class MessageInputLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    // fetch("http://www.astiqsolution.com/")
    //   .then((res) => res.json())
    //   .then((json) => console.log("json", json));
    this.fetchDataObj();
  }

  fetchDataObj() {
    async function fetchData() {
      // try {
      //   // const response = axios.get(this.props.url);
      //   const response = await axios.get("https://example.com/data", {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: "Bearer YOUR_AUTH_TOKEN",
      //     },
      //   });
      //   const html = response.data;
      //   const titleRegex = /<title[^>]*>([^<]+)<\/title>/;
      //   const descriptionRegex = /<meta name="description" content="([^"]+)">/;
      //   const imageRegex = /<meta property="og:image" content="([^"]+)">/;
      //   const title = html.match(titleRegex)[1];
      //   const description = html.match(descriptionRegex)[1];
      //   const image = html.match(imageRegex)[1];

      //   this.setState({
      //     data: {
      //       title: title,
      //       description: description,
      //       image: image,
      //     },
      //   });
      //   console.log("this.state.data", response);
      //   console.log(
      //     "html , title , decription, image,this.state.daa",
      //     html,
      //     title,
      //     description,
      //     image
      //   );
      // } catch (error) {
      //   console.error(error);
      // }
    }
    // fetchData();
  }

  render() {
    let {
      className,
      bgcolor = "",
      marginLeft = "",
      src,
      url,
      text,
      styleData,
    } = this.props;

    console.log(
      "html , title , decription, image,this.state.daa 22",
      this.state.data.html
    );

    return (
      <div style={styleData}>
        <diV
          class={className}
          style={{
            display: "flex",
            width: "339px",
            height: "67px",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "45px",
            borderRadius: "11px",
            padding: "0px",
            backgroundColor: bgcolor ? bgcolor : "",
            marginLeft: marginLeft ? marginLeft : "",
          }}
        >
          <div>
            <a href={url} target="_blank" rel="noopener noreferrer">
              {this.state.data.title && this.state.data.title}
              KKKKKKKLLLLLLL
            </a>
            <p>{this.state.data.description && this.state.data.description}</p>
            <img
              src={this.state.data.image && this.state.data.image}
              alt={this.state.data.title && this.state.data.title}
            />
          </div>
          {/* <a href={url} target="_blank" rel="noopener noreferrer">
          {text}
        </a> */}
          {/* <img
          src={src}
          alt="A beautiful landscape"
          width="330"
          height="186"
          style={{ alignContent: "center", borderRadius: "11px" }}
        /> */}
        </diV>
      </div>
    );
  }
}
