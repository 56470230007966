import TubeLogo from "../utils/TubeLogo";
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import theme from "../../assets/styles/theme/theme";
import { MainContainer } from "../../assets/styles/theme/styles";
import Images from "../utils/Images";

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  text-align: center;
  width: 100%;
  margin: 17px auto;
  flex-direction: column;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */

  & > *:not(:first-child) {
    flex-grow: 1;
  }

  @media screen and (max-width: 800px) {
    & > *:first-child {
      margin-bottom: 1rem;
    }
  }
`;

const LinkList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const LinkListItem = styled.li`
  margin-bottom: 10px;
`;

const StyledLink = styled(Link)`
  position: relative;
  overflow: hidden;
  text-decoration: none;
  display: block;
  width: fit-content;
  cursor: pointer;
  font-size: 14px;
  color: #7d7c87;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background-color: ${theme.colors.primary};
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  &:hover:after {
    transform: scaleX(1);
  }
`;

const SmallCard = styled.span`
  text-align: center;
  margin-top: 50px;
  font-size: 14px;
  a {
    color: black;
    text-decoration: none;
  }
`;

const CopyrightText = styled.p`
  text-align: center;
  font-size: 12px;
  margin: 1rem 0;
`;



const TextContainer = styled.div`
  color: ${theme.colors.lightgrey};
  font-size: ${theme.fontSizes.paragraph6};

  & > p:first-child {
    // margin-top: 5px;
    margin-bottom: 14px;
    max-width: 840px;
    width: 100%;

    @media (min-width: 1020px) {
    margin-top: 14px;
    font-size: ${theme.fontSizes.paragraph3};

    }
  }


`;

const PoweredWrapper = styled.p`
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 14px;
  justify-content: center;

  @media (min-width: 1020px) {
    justify-content: end;
  }
`;

const BrandLogo = styled.img`
  width: 130px;
  height: auto;
`;

function Footer() {
  return (
    <MainContainer>
      <CardWrapper>
        {/* <BrandLogo style={{ margin: "0px auto" }} /> */}
        {/* <Link to='/'> */}
        <BrandLogo src={Images.company_logo} />
        {/* </Link> */}

        <TextContainer>
          <p>
          Empowering Brand Agents with historical perspective of customer conversations irrespective of the communication channel for an appropriate and efficient resolutions.
          </p>
          Email us :{" "}
          <a href="mailto:hello@tubelightcommunications.com">
            hello@tubelightcommunications.com
          </a>
        </TextContainer>
        {/* <section className='col-lg-6 col-sm-12'>
         <h1 className='font-bold' style={{ fontWeight: 'bold', fontSize: '25px', paddingBottom: '20px' }}>
           Contact
         </h1>
         <p>
           Office : B-1702, Lotus Corporate Park, <br/> Western Express Hwy, Goregaon, Mumbai, <br/> Maharashtra 400063.
           <br/>
           <br/>
           Tel: +91 75894 75894
           <br/>
           <br/>
           Email : hello@tubelightcommunications.com
         </p>
       </section> */}
      </CardWrapper>
      <PoweredWrapper>
        Powered by <TubeLogo />
      </PoweredWrapper>
    </MainContainer>
  );
}

export default Footer;
