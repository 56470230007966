import React, { useState } from "react";
import { MainContainer } from "../assets/styles/theme/styles";
import styled, { keyframes, css } from "styled-components";
import styles from "./PricingComponent.module.css";
import { useNavigate } from "react-router-dom";
import Images from "./utils/Images";
import { FaInfoCircle } from "react-icons/fa";
import { LuIndianRupee } from "react-icons/lu";
import { Link } from "react-router-dom";
import PricingTable from "./Pages/popuptable/PricingTable";
import theme from "../assets/styles/theme/theme";
import Heading from "./utils/Heading";
import { Contact } from "./utils/Contact";

const formatPrice = (price) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(price)
    .replace("₹", "");
};

export const PricingComponent = () => {
  const [selectedCard, setSelectedCard] = useState(1);
  const [showTable, setShowTable] = useState(false);
  const [selectedUser, setSelectedUser] = useState("1");
  const navigate = useNavigate();
  const [isAnnual, setIsAnnual] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [infoPopupVisible, setInfoPopupVisible] = useState(null);

  const infoPopups = [
    {
      monthly: [
        { feature: "Free Credits", value: 500*selectedUser },
        { feature: "Web Chat Sessions", value: 0.12 },
        { feature: "WA Marketing Messages", value: '0.90' },
        { feature: "WA other messages", value: '0.50' },

        { feature: "RCS Marketing Messages", value: 0.45 },
        { feature: "RCS other messages", value: 0.25 },
        { feature: "Voice Call Rates", value: "NA" },
        { feature: "Voice Channel", value: "NA" },
        { feature: "SMS", value: '0.20' },
        { feature: "Email", value: 0.12 },
        { feature: "Agent License", value: 1.0 * selectedUser },
      ],
      annual: [
        { feature: "Free Credits", value: (selectedUser > 1) ? ((selectedUser-1) * 12 * 500 + 500) : 500},
        { feature: "Web Chat Sessions", value: 0.12 },
        { feature: "WA Marketing Messages", value: '0.90' },
        { feature: "WA other messages", value: '0.50' },

        { feature: "RCS Marketing Messages", value: 0.45 },
        { feature: "RCS other messages", value: 0.25 },
        { feature: "Voice Call Rates", value: "NA" },
        { feature: "Voice Channel", value: "NA" },
        { feature: "SMS", value: '0.20 '},
        { feature: "Email", value: 0.12 },
        { feature: "Agent License", value: 1.0 * selectedUser },
      ],
    },
    {
      monthly: [
        { feature: "Free Credits", value: 1000*selectedUser },
        { feature: "Web Chat Sessions", value: '0.10' },
        { feature: "WA Marketing Messages", value: 0.85 },
        { feature: "WA other messages", value: 0.45 },

        { feature: "RCS Marketing", value: '0.40' },
        { feature: "RCS other messages", value: '0.20' },
        { feature: "Voice Call Rates", value: '0.80' },
        { feature: "Voice Channel", value: 2 },
        { feature: "SMS", value: 0.17 },
        { feature: "Email", value: '0.10' },
        { feature: "License", value: 1.0 * selectedUser },
      ],
      annual: [
        { feature: "Free Credits", value: 12000*selectedUser },
        { feature: "Web Chat Sessions", value: '0.10' },
        { feature: "WA Marketing Messages", value: 0.85 },
        { feature: "WA other messages", value: 0.45 },

        { feature: "RCS Marketing", value: '0.40' },
        { feature: "RCS other messages", value: '0.20' },
        { feature: "Voice Call Rates", value: '0.80' },
        { feature: "Voice Channel", value: 2 },
        { feature: "SMS", value: 0.17 },
        { feature: "Email", value: '0.10' },
        { feature: "License", value: 1.0 * selectedUser },
      ],
    },
    {
      monthly: [
        { feature: "Free Credits", value: 5000 },
        { feature: "Web Chat Sessions", value: 0.08 },
        { feature: "WA Marketing Messages", value: 0.82 },
        { feature: "WA other messages", value: 0.42 },

        { feature: "RCS Marketing Messages", value: 0.38 },
        { feature: "RCS other messages", value: 0.18 },
        { feature: "Voice Call Rates", value: "Unlimited calls" },
        { feature: "Voice Channel", value: 20 },
        { feature: "SMS", value: 0.14 },
        { feature: "Email", value: 0.09 },
        { feature: "Agent License", value: 10 },
      ],
      annual: [
        { feature: "Free Credits", value: 60000 },
        { feature: "Web Chat Sessions", value: 0.08 },
        { feature: "WA Marketing Messages", value: 0.82 },
        { feature: "WA other messages", value: 0.42 },

        { feature: "RCS Marketing Messages", value: 0.38 },
        { feature: "RCS other messages", value: 0.18 },
        { feature: "Voice Call Rates", value: "Unlimited calls" },
        { feature: "Voice Channel", value: 20 },
        { feature: "SMS", value: 0.14 },
        { feature: "Email", value: 0.09 },
        { feature: "Agent License", value: 10 },
      ],
    },
  ];

  const handleCardClick = (index) => {
    // console.log(`selected card id: ${index}`);
    setSelectedCard(index);
  };

  const handleClick = () => {
    navigate("/contact-us");
  };

  const getInfoPopupsData = (index) => {
    const selectedData = infoPopups[index];
    let selected = !isAnnual ? selectedData.annual : selectedData.monthly;
  
    // // Check if the card is the 3rd one
    // if (index !== 2) {
    //   // If it's not the 3rd card, multiply the first value by selectedUser
    //   selected[0].value = selected[0].value * selectedUser;
    // }
  
    return selected;
  };

  const handleInfoIconHover = (index) => {
    setInfoPopupVisible(index);
  };

  const handleInfoIconLeave = () => {
    setInfoPopupVisible(null);
  };

  const pricingData = [
    {
      id: 27,
      plan: "Connect",
      // desc:'@599',
      monthlyPrice: 599,
      annualPrice: 599 * 12,
      // fc: 'FCV* Rs. 500',

      discountedPrice: Math.round(599 * 12 * 0.9), // 10% discount
      monthlyCredits: 500,
      annualCredits: 500 * 12,
      features: [
        "Price Breakup",
        "Free Credits Rs. 500",

        "Chat",
        "1 License",
        "0 Voice Channels",
        "10 API calls/sec",
        "User Creation Limit - 10",
        // "Price / Channel",
      ],
      annualDiscount: 10,
    },
    {
      id: 28,
      plan: "Engage",
      // desc:'@1499',

      monthlyPrice: 1499,
      annualPrice: 1499 * 12,
      // fc: 'FCV* Rs. 1000',

      discountedPrice: Math.round(1499 * 12 * 0.89), // 15% discount
      monthlyCredits: 1000,
      annualCredits: 1000 * 12,
      features: [
        "Price Breakup",

        "Free Credits Rs. 1000",
        "Chat + Calls",
        "1 License",
        "2 Voice Channels",
        "50 API calls/sec",
        "User Creation Limit - 30",
        // "Price / Channel",
      ],
      annualDiscount: 15,
    },
    {
      id: 29,
      plan: "Enhance",
      // desc:'@2099',

      monthlyPrice: 20990,
      annualPrice: 20990 * 12,
      // fc: 'FCV* Rs. 5000',

      discountedPrice: Math.round(20990 * 12 * 0.75), // 10% discount
      monthlyCredits: 5000,
      annualCredits: 5000 * 12,
      features: [
        "Price Breakup",

        "Free Credits Rs. 5000",
        "Chat + Unlimited Calls",
        "10 License",
        "20 Voice Channels",
        "100 API calls/sec",
        "User Creation Limit - 50",
        // "Price / Channel",
      ],
      annualDiscount: 25,
    },
  ];

  // const selectedPricing = isAnnual
  // ? pricingData.map((item) => ({
  //     ...item,
  //     formattedPrice: formatPrice(item.monthlyPrice * selectedUser),
  //     discountedPrice: formatPrice(item.discountedPrice * selectedUser),
  //   }))
  // : pricingData.map((item) => ({
  //     ...item,
  //     formattedPrice: formatPrice(item.annualPrice * selectedUser),
  //     discountedPrice: formatPrice(item.discountedPrice * selectedUser),
  //   }));

  const selectedPricing = pricingData.map((item) => {
    let formattedPrice, discountedPrice;

    if (item.id === 29) {
      // For card ID 29, don't apply the selectedUser multiplication
      formattedPrice = isAnnual
        ? formatPrice(item.monthlyPrice)
        : formatPrice(item.annualPrice);
      discountedPrice = isAnnual
        ? formatPrice(item.discountedPrice)
        : formatPrice(item.discountedPrice);
    } else {
      // For other card IDs, apply the selectedUser multiplication
      formattedPrice = isAnnual
        ? formatPrice(item.monthlyPrice * selectedUser)
        : formatPrice(item.annualPrice * selectedUser);
      discountedPrice = isAnnual
        ? formatPrice(item.discountedPrice * selectedUser)
        : formatPrice(item.discountedPrice * selectedUser);
    }

    return {
      ...item,
      formattedPrice,
      discountedPrice,
    };
  });

  const handleBuyNow = (plan) => {
    if (plan) {
      setSelectedPlan(plan);

      const selectedAmount = !isAnnual ? plan.annualPrice : plan.monthlyPrice;
      const billingFrequency = !isAnnual ? "Annual" : "Monthly";
      const selectedCredits = !isAnnual
        ? plan.annualCredits
        : plan.monthlyCredits;

      navigate("/licences", {
        state: {
          selectedUser: plan.plan === "Enhance" ? 10 : selectedUser,
          ...plan,
          infoPopups,
          billingFrequency,
          selectedAmount,
          selectedCredits,
        },
      });
    }
  };

  const handleInfoIconClick = (index) => {
    setInfoPopupVisible((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <>
      <MainContainer>
        {/* <HowHeading>Pricing and Plans</HowHeading> */}
        <Heading level={1} style={{ marginBottom: "0px" }}>
          Pricing and Plans
        </Heading>
        <HowHeadings>
          From Micro Businesses to Enterprises, we have you covered. Let 1iNbox
          Supercharge your Customer Engagements with pay as you go introductory
          starter plans to cater to your needs.
        </HowHeadings>

        <div className={styles.main}>
          <div className={styles.first}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // border: "2px solid gray",
                borderRadius: "50px",
                boxShadow:
                  "0 0 5px rgba(255, 255, 255, 0.5) inset, 0 0 10px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              <button
                onClick={() => setIsAnnual(true)}
                style={{
                  backgroundColor: isAnnual ? "#477af6" : "#ffffff",
                  color: isAnnual ? "#ffffff" : "#333333",
                  borderRadius: "50px",
                  cursor: "pointer",
                  outline: "none",
                  border: "none",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  // border: "2px solid rgb(184,184,184)",

                  fontWeight: "bold",
                }}
              >
                Monthly
              </button>
              <button
                onClick={() => setIsAnnual(false)}
                style={{
                  backgroundColor: !isAnnual ? "#477af6" : "#ffffff",
                  color: !isAnnual ? "#ffffff" : "#333333",
                  borderRadius: "50px",
                  cursor: "pointer",
                  outline: "none",
                  border: "none",
                  fontSize: "1rem",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  // border: "2px solid rgb(184,184,184)",
                  fontWeight: "bold",
                }}
              >
                Annually
              </button>
            </div>
          </div>
          <div className={styles.verticalline}></div>
          <div className={styles.second}>
            <h1 style={{ fontSize: "25px", fontWeight: "bold" }}>
              Save upto 25% <br />
            </h1>
            <p>by paying annually</p>
          </div>
          <div className={styles.verticalline}></div>

          <div className={styles.third}>
            <p style={{ fontSize: "20px" }}>Number of License</p>

            <div className={styles.user}>
              <div className={styles.inputContainer}>
                <input
                  type="range"
                  min="1"
                  max="10"
                  step="1"
                  value={parseInt(selectedUser)}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    setSelectedUser(value.toString());
                    handleBuyNow(selectedPlan);
                  }}
                  className={styles.inputRange}
                  style={{
                    "--fillPercentage": `${(selectedUser / 10) * 100}%`,
                  }}
                />
              </div>
              <p className={styles.valueText}>
                {selectedUser === "20" ? "20" : selectedUser}
              </p>
            </div>
          </div>
        </div>

        <PricingContainer>
          {selectedPricing.map((item, index) => (
            <PricingCard
              index={index}
              key={index}
              // recommended={index === 1}
              selected={index === selectedCard}
              onMouseEnter={() => handleCardClick(index)}
            >
              {/* {index === 1 && <RecommendedLabel>Recommended</RecommendedLabel>} */}

              <CardHead index={index} recommended={index === 1}>
                {index === 1 && (
                  <div className={styles.connnected}>
                    <img
                      className={styles.imgStyle}
                      style={{
                        position: "absolute",
                        width: "30%",
                        height: "auto",
                        left: "71%",
                        top: "-5px",
                        zIndex: "5",

                        // Media queries for responsive design
                        "@media (max-width: 768px)": {
                          width: "30%",
                          left: "20%",
                        },

                        "@media (max-width: 480px)": {
                          width: "50%",
                          left: "25%",
                        },
                      }}
                      src={Images.recom}
                      alt=""
                    />
                  </div>
                )}

{index === 2 && (
                  <div className={styles.connnected}>
                    <img
                      className={styles.imgStyle}
                      style={{
                        position: "absolute",
                        width: "30%",
                        height: "auto",
                        left: "71%",
                        top: "-5px",
                        zIndex: "5",

                        // Media queries for responsive design
                        "@media (max-width: 768px)": {
                          width: "30%",
                          left: "20%",
                        },

                        "@media (max-width: 480px)": {
                          width: "50%",
                          left: "25%",
                        },
                      }}
                      src={Images.unli}
                      alt=""
                    />
                  </div>
                )}

                {index === 0 && (
                  <div className={styles.connnected}>
                    <img
                      className={styles.imgStyle}
                      style={{
                        position: "absolute",
                        width: "30%",
                        height: "auto",
                        left: "71%",
                        top: "-5px",
                        zIndex: "5",

                        // Media queries for responsive design
                        "@media (max-width: 768px)": {
                          width: "30%",
                          left: "20%",
                        },

                        "@media (max-width: 480px)": {
                          width: "50%",
                          left: "25%",
                        },
                      }}
                      src={Images.flages}
                      alt=""
                    />
                  </div>
                )}

                {/* {index === 1 && <RecommendedLabel>Recommended</RecommendedLabel>} */}

                <img src={Images.blue} alt="" />

                <h1
                  style={{
                    fontSize: "40px",
                    textAlign: "center",
                    paddingTop: "20px",
                    padding: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  {item.plan}
                </h1>

                <h1
                  style={{
                    fontSize: "30px",
                    textAlign: "center",
                    paddingTop: "10px",
                    margin: "1rem",
                  }}
                >
                  {item.desc}
                </h1>

                {/* <h3 style={{ fontSize: "15px", textAlign: "center" }}>
                  {item.desc}
                </h3> */}
                <p
                  style={{
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {item.fc}
                </p>
              </CardHead>

              <div className={styles.class}></div>

              {isAnnual && (
                <div
                  style={{
                    fontSize: "50px",
                    color: "#477af6",
                    marginTop: "10px",
                    // textAlign: "center",
                    // display:'inline-block'
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LuIndianRupee />

                  {item.formattedPrice}
                </div>
              )}

              {!isAnnual && (
                <div
                  style={{
                    fontSize: "25px",
                    color: "#477af6",
                    // marginTop: "10px",
                    // textAlign: "center",
                    // display:'inline-block'
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "line-through",
                  }}
                >
                  <LuIndianRupee />

                  {item.formattedPrice}
                </div>
              )}

              {!isAnnual && (
                <div
                  style={{
                    fontSize: "50px",
                    color: "#477af6",
                    // marginTop: "10px",
                    // textAlign: "center",
                    // display:'inline-block'
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LuIndianRupee />

                  {item.discountedPrice}
                </div>
              )}

              <p
                style={{
                  textAlign: "center",
                  marginTop: "-0.5rem",
                  marginBottom: "1rem",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "-0.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {isAnnual && index === 2
                    ? "10 License / month"
                    : isAnnual
                    ? `${selectedUser} License / month`
                    : "per year * paid annually"}
                </p>
              </p>
              <hr style={{ border: "1px solid #ccc", marginBottom: "10px" }} />

              <ul style={{ padding: "0px 1rem" }}>
                {item.features.map((feature, i) => (
                  <li key={i} style={{ marginLeft: i === 0 ? 0 : "5px" }}>
                    {i === 0 ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onMouseEnter={() => handleInfoIconHover(index)}
                          onMouseLeave={handleInfoIconLeave}
                        >
                          <span
                            style={{
                              display: "flex",
                              marginRight: "5px",
                              color: "lightblue",
                            }}
                          ></span>
                          &#10003; &nbsp;
                          {item.features[0]}
                          <div
                            style={{
                              display: "flex",
                              marginRight: "5px",
                              color: "lightblue",
                              paddingLeft: "5px",
                              paddingTop: "5px",
                            }}
                          >
                            <FaInfoCircle id={`infoIcon${index}`} />
                          </div>
                          <InfoPopup
                            id={`infoPopup${index}`}
                            visible={infoPopupVisible === index}
                          >
                            {getInfoPopupsData(index).map(
                              (infoItem, popupIndex) => (
                                <div
                                  key={popupIndex}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "10px",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      textAlign: "left",
                                    }}
                                  >
                                    {infoItem.feature}
                                  </span>
                                  :{" "}
                                  <span style={{ color: "blue" }}>
                                    {infoItem.value}
                                  </span>
                                </div>
                              )
                            )}
                          </InfoPopup>
                        </div>
                      </>
                    ) : (
                      <>
                        <span
                          style={{
                            alignContent: "left",
                            textAlign: "left",
                            marginRight: "5px",
                          }}
                        >
                          &#10003;
                        </span>{" "}
                        {feature}
                        {/* Add discounted price logic for the 3rd card in the monthly section */}
                        {/* {index === 2 &&   isAnnual ? (
            <span style={{ color: "red", marginLeft: "5px" }}>
              Discounted Price
            </span>
          ) : null} */}
                      </>
                    )}
                  </li>
                ))}
              </ul>

              <a
                href="#pricingTable"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // textAlign: "center",
                  fontSize: "12px",
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() => {
                  console.log(`I got clicked`);
                  setShowTable(true);
                }}
              >
                View more
              </a>
              <BuyButton
                style={{ backgroundColor: "#477af6" }}
                onClick={() => handleBuyNow(item)}
              >
                Buy Now
              </BuyButton>
            </PricingCard>
          ))}
        </PricingContainer>
      </MainContainer>
      <Contact heading={`For Customized Plans`} btntext="Get In Touch" />

      {showTable && (
        <div id="pricingTable">
          <PricingTable />
        </div>
      )}

        <MainContainer>
        <NotesContainer>
          <div>
            <h1>
              Important
            </h1>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <span style={{ marginRight: "10px"}}>1.</span>
              <p>
                The prices listed here apply exclusively to India and
                destinations within the country. For inquiries regarding
                international destinations, kindly get in touch with our support
                team.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <span style={{ marginRight: "10px"}}>2.</span>
              <p>
              Monthly free credit is valid for 30 days only. The free credits will be refreshed monthly as per plan. Any balance of the free credit’s  will not be carried forward to the next month. Any credit added by the customer will be carried
                forward.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <span style={{ marginRight: "10px"}}>3.</span>
              <p>
                To avail of the services, registration with the applicable
                operators is mandatory. The plans mentioned here are independent
                of any registration charges imposed by respective operators.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <span style={{ marginRight: "10px"}}>4.</span>
              <p>
                To activate SMS services, DLT registration is a mandatory
                requirement. Click{""}
                <span style={{ color: "blue" }}>
                  <a
                    href="#"
                    target="_self"
                    onClick={() => window.open("/DLT Registration Process.pdf")}
                  >
                    here
                  </a>
                </span>{" "}
                to complete the DLT registration process.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <span style={{ marginRight: "10px"}}>5.</span>
              <p>
                To start WhatsApp Services you can Self Sign Up{""}
                <span style={{ color: "blue" }}>
                  <Link to="/sign-up">
                    here
                  </Link>
                </span>{" "}
                for free.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <span style={{ marginRight: "10px"}}>6.</span>
              <p>
                For Google RCS Service activation{""}
                <span style={{ color: "blue" }}>
                  <Link to="/enquiry">
                    contact us
                  </Link>
                </span>
                .
              </p>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <span style={{ marginRight: "10px"}}>7.</span>
              <p>
                These are introductory plans offered for a limited period only
                and Tubelight Communication Ltd reserves the right to Stop or
                Modify the plan, without assigning any reason, by giving a
                15days advance notice, by email.
              </p>
            </div>
          </div>
        </NotesContainer>
        </MainContainer>
    </>
  );
};

const NotesContainer = styled.div`
border-radius: 25px;
padding: 1.5rem 2rem;
box-shadow: rgba(71,122,246,0.3) 0px 0px 0px 3px;

h1 {
  color: #EE4B2B;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 12px 
}

p {
  color: ${theme.colors.lightgrey};
}

@media (max-width: 720px) {
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
`;

const CardHead = styled.div`
  height: 70px;
  width: 100%;
  position: relative;
  background-color: #477af6;
  color: white;
  border-top-left-radius: 30px 30px;
  border-top-right-radius: 30px 30px;

  ${({ index }) =>
    (index === 0 || index === 2) &&
    css`
      border-top-left-radius: 30px 30px;
      border-top-right-radius: 30px 30px;
      // height: 100px;
    `}

  img {
    width: 100%;
    height: 13px;
    position: absolute;
    bottom: -10px;
    left: 0;
  }
`;

const InfoPopup = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 2;
  display: ${({ visible }) => (visible ? "block" : "none")};

  span:first-child {
    width: 96px;
  }
  @media (min-width: 1400px) {
    margin-left: 11rem;
    margin-top: 12rem;
  }
  @media (max-width: 1400px) and (min-width: 726px) {
    margin-left: 8rem;
    margin-top: 12rem;
  }
  @media (max-width: 700px) {
    margin-top: 10rem;

    margin-left: 10rem;
  }
`;

const PricingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 2rem;
`;

const PricingCard = styled.div`
  border: 1px solid #ccc;
  text-align: left;
  margin: 20px;
  border-radius: 30px;
  box-shadow: ${({ selected }) =>
  selected
      ? "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset" 
      : "none"};
  transition: box-shadow 0.2s ease-in-out;

  flex: 1;
  min-width: 276px;
  margin: 10px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    flex-basis: calc(50% - 20px);
  }

  @media screen and (max-width: 480px) {
    flex-basis: 100%;
  }
`;

// Apply the 'second-card' class to the second card element
// You can do this dynamically based on your application logic
// For example, if it's the second card in a list, you can apply it conditionally.



const HowHeadings = styled.p`
  font-size: ${theme.fontSizes.paragraph3};
  font-weight: 400;
  color: ${theme.colors.lightgrey};
  padding-top: 15px;
  padding-bottom: 15px;
  @media (min-width: 1020px) {
    font-size: ${theme.fontSizes.paragraph4};
  }
`;

const BuyButton = styled.button`
  background-color: #3498db;
  color: #ffffff;
  border: none;
  padding: 10px 50px;
  border-radius: 5px;
  cursor: pointer;
  margin: 16px auto;
  display: block;

  &:hover {
    background-color: #297fb8;
  }
`;

export default PricingComponent;
