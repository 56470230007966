import React, { Component } from "react";
// import "../components/style.css";
import MessageInputImage from "./MessageInputImage";
import MessageInputVideo from "./MessageInputVideo";
import MessageInputLink from "./MessageInputLink";
import MessageInputDocuments from "./MessageInputDocuments";
import MessageInputAudio from "./MessageInputAudio";
import MessageInputLocation from "./MessageInputLocation";
import MessageInputConfirm from "./MessageInputConfirm";
import moment from "moment";
import WebSocketInstance from "../../../websocket/singleInbox";
// import CustomDropdown from "./CustomDropdown";
// import { chatAppSelector } from "../../../../store/selector";
// import { RecentList } from "../../../theme/theme1/common/components/dialpad";


class MsgComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      contactId: sessionStorage.getItem("contactId") ? sessionStorage.getItem("contactId") : null,
    }
  }

  scrollToBottom = () => {
    return this.messagesEnd ? this.messagesEnd.scrollIntoView({ behavior: "smooth" }) : null;
  }

  componentDidMount() {
    // if(this.props.data && this.props.data.length <= 8) {
    //   console.log("called componentDidMount =>");
      this.scrollToBottom();

    // }
  }

  // componentDidUpdate(prevProps) {

  //   // when user clicks on new contact
  //   // if(prevProps.selectedUser && this.props.selectedUser) {
  //   //   console.log("called =>", prevProps.selectedUser, this.props.selectedUser);

  //   //   if(prevProps.selectedUser.id != this.props.selectedUser.id) {
  //   //     this.scrollToBottom();
  //   //   }
  //   // }

  //   if(prevProps.chatMessagesList && this.props.chatMessagesList) {

  //     let prevChat = prevProps.chatMessagesList;
  //     // console.log(prevChat.length != this.props.chatMessagesList.length);

  //     // when message length increases && last message id is different
  //     if(prevChat.length != this.props.chatMessagesList.length &&
  //        prevChat[prevChat.length-1].message_id != this.props.chatMessagesList[this.props.chatMessagesList.length-1].message_id
  //       ) {
  //       // console.log(prevChat[prevChat.length-1].message_id, this.props.chatMessagesList[this.props.chatMessagesList.length-1].message_id);

  //       this.scrollToBottom();
  //     }
  //   }

  // }


  renderTimestamp = timestamp => {
    const today = moment().startOf('day');
      const messageDate = moment(timestamp).startOf('day');
    
      // if the timestamp is today, show the time instead of the date
      if (moment(today).diff(messageDate, 'days') === 0) {
        return moment(timestamp).format('h:mm A');
      }
    
      // if the timestamp is within the current year, show the date and month
      if (moment().diff(messageDate, 'years') === 0) {
        return moment(timestamp).format('MMM D, h:mm A');
      }
    
      // otherwise, show the full date
      return moment(timestamp).format('MMM D, YYYY, h:mm A');
    
    // return new Date(timestamp).toLocaleTimeString('en-US',
    // {timeZone:'Asia/Kolkata',hour12:true,hour:'numeric',minute:'numeric'});
    // let prefix = ''; 
    // const timeDiff = Math.round((new Date().getTime() - new Date(timestamp).getTime())/60000);
    // if (timeDiff < 1) { // less than one minute ago
    //     prefix = 'just now...';
    // } else if (timeDiff < 60 && timeDiff > 1) { // less than sixty minutes ago
    //     prefix = `${timeDiff} min ago`;
    // } else if (timeDiff < 24*60 && timeDiff > 60) { // less than 24 hours ago
    //     prefix = `${Math.round(timeDiff/60)} hr ago`;
    // } else if (timeDiff < 31*24*60 && timeDiff > 24*60) { // less than 7 days ago
    //     prefix = `${Math.round(timeDiff/(60*24))} days ago`;
    // } else {
    //     prefix = `${new Date(timestamp)}`;
    // }
    // console.log(prefix);
    // return prefix
  }

  // getTypeUrl(type) {
  //   if (type == 'inbound_call' || type == 'incomming' ) {
  //     return '/images/icons/incoming.png';
  //   } else if( type == 'Missed' ){
  //     return '/images/icons/missed.png';
  //   }
  //   return '/images/icons/outgoing.png';
  // }

  waitForSocketConnection(callback) {
    const component = this;
    setTimeout(
        function () {
        if (WebSocketInstance.state() === 1) {
            console.log("Connection is made")
            callback();
            return;
        } else {
            console.log("wait for connection...")
            component.waitForSocketConnection(callback);
        }
    }, 100);
  }
  
  handleResponseBtnClick(button) {

    const messageObject = {
      // from: currentUser.email,
      content: {type: "reply", payload: button.payload, text: button.title},
      channel: "web",
      phone_number: this.state.contactId
    };

    this.waitForSocketConnection(() => {
        console.log("called =>")
        WebSocketInstance.newChatMessage(messageObject);
    })    

  }
  
  render() {
    let { data, place, messageEvent } = this.props;
    console.log("data MSGCOM", data);
    // let currentUser = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")).email : "";

    // const handleChange = option => {
    //   console.log(`Selected option: ${option}`);
    // };
  
    if (place == "left") {
      return (
        <React.Fragment>
          {/* {currentUser !== data.agent.username} */}

          {data && data.message_json && (data.message_json.type === "text" || data.message_json.type === "reply") && (
            <div className="row" ref={(el) => { this.messagesEnd = el; }}>
              <div
                className=""
                style={{ display: "flex",
                flexDirection: "column",
                marginBottom: "10px",
                justifyContent: "flex-start" }}
              >

                
                <div
                  className="chat-bubble chat-bubble--left"
                  style={{ width: "fit-content", maxWidth: "85%", 
                  wordWrap: "break-word",
                  // marginLeft: "45px", 
                  display: "flex"
                 
                 }}
                >
                  {data.message_json.text || <span style={{ color: "red", fontStyle: "italic" }}>Failed to read message!</span>}
                  {/* <IoIosArrowDown size={12} /> */}
                  {/* <CustomDropdown 
                  className="chat-quick-reply--left" 
                  label="Select an option" 
                  option={"chatReplyOptions"} 
                  onChange={handleChange}
                  icon="expand_more"/> */}

                </div>
                
                  {data.message_json.buttons && data.message_json.buttons.length && (
                  <div className="reponse_parent"> {data.message_json.buttons.map((btn, index) => (
                    <button className="response_btn" key={index} onClick={() => this.handleResponseBtnClick(btn)}>{btn.title || "button"}</button>
                  ))}
                  </div>)}
                
                
                <div className="chat-last-seen--left">
                    <small>{this.renderTimestamp(data.time)}</small>
                    </div>
              </div>


            </div>
          )}

          {data && data.message_json && data.message_json.type === "image" && ( 
            <div className="row no-gutters" ref={(el) => { this.messagesEnd = el; }}>
              <div
                className=""
                style={{ display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
                justifyContent: "flex-start" }}
              >

                
                <div
                  className="chat-bubble chat-bubble--left"
                  style={{ width: "fit-content", 
                  // maxWidth: "75%", 
                  wordWrap: "break-word",
                  // marginLeft: "45px", 
                  display: "flex"
                 
                 }}
                >
                  <MessageInputImage
                    // className={"chat-bubble chat-bubble--left"}
                    src={data.message_json.url}
                    fileName={data.message_json.file_name}
                    styleData={{  }}
                  />
            
                  {/* <IoIosArrowDown size={12} /> */}
                  {/* <CustomDropdown 
                  className="chat-quick-reply--left" 
                  label="Select an option" 
                  option={"chatReplyOptions"} 
                  onChange={handleChange}
                  icon="expand_more"/> */}

                </div>

                <div className="chat-last-seen--left">
                    <small>{this.renderTimestamp(data.time)}</small>
                    </div>
              </div>
            </div>
          )}

          {data && data.message_json && data.message_json.type == "video" && (
            <div className="row no-gutters" ref={(el) => { this.messagesEnd = el; }}>
              <div
                className=""
                style={{ display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
                justifyContent: "flex-start" }}
              >

                <div
                  className="chat-bubble chat-bubble--left"
                  style={{ width: "fit-content", 
                  // width: "75%", 
                  wordWrap: "break-word",
                  // marginLeft: "45px", 
                  display: "flex"
                 
                 }}
                >
                  <MessageInputVideo 
                    mimeType={data.message_json.mime_type}
                    src={data.message_json.url}
                    fileName={data.message_json.file_name}
                    // className={"chat-bubble chat-bubble--left"}
                  />
                  {/* <IoIosArrowDown size={12} /> */}
                  {/* <CustomDropdown 
                  className="chat-quick-reply--left" 
                  label="Select an option" 
                  option={"chatReplyOptions"} 
                  onChange={handleChange}
                  icon="expand_more"/> */}

                </div>

                <div className="chat-last-seen--left">
                    <small>{this.renderTimestamp(data.time)}</small>
                    </div>
              </div>
            </div>
            
          
          )}

          {data && data.type == "link" && (
            <MessageInputLink
              className={"chat-bubble chat-bubble--left"}
              url={
                "https://pixabay.com/videos/christmas-christmas-decorations-141964/"
              }
              text={"DON"}
            />
          )}

          {data && data.message_json && data.message_json.type === "document" && (

            <div className="row no-gutters" ref={(el) => { this.messagesEnd = el; }}>
              <div
                className=""
                style={{ display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
                justifyContent: "flex-start" }}
              >

                <div
                  className="chat-bubble chat-bubble--left"
                  style={{ width: "fit-content", 
                  // width: "75%", 
                  wordWrap: "break-word",
                  // marginLeft: "45px", 
                  display: "flex"
                 
                 }}
                >
                  <MessageInputDocuments
                    src={data.message_json.url}
                    fileName={data.message_json.file_name}
                    // className={"chat-bubble chat-bubble--left"}
                  />
            {/* <IoIosArrowDown size={12} /> */}
                  {/* <CustomDropdown 
                  className="chat-quick-reply--left" 
                  label="Select an option" 
                  option={"chatReplyOptions"} 
                  onChange={handleChange}
                  icon="expand_more"/> */}

                </div>

                <div className="chat-last-seen--left">
                    <small>{this.renderTimestamp(data.time)}</small>
                    </div>
              </div>
            </div>
            
          
          )}


          {data && data.message_json && ["manual_call","auto_call","inbound_call"].includes(data.message_json.type) && (
            <div className="row no-gutters" ref={(el) => { this.messagesEnd = el; }}>
            <div
              className=""
              style={{ display: "flex",
              flexDirection: "column",
              marginBottom: "20px",
              justifyContent: "flex-start" }}
            >

              <div
                className="chat-bubble chat-bubble--left"
                style={{ width: "fit-content", width: "339px", 
                wordWrap: "break-word",
                // marginLeft: "45px", 
                display: "flex",
                flexDirection: "column"
               }}
              >
                {/* <CustomDropdown className="chat-quick-reply--right" 
                  rightAlign={true}
                  label="Select an option" 
                  option={"chatReplyOptions"} 
                  icon="expand_more"/> */}
  
                  {/* {data && data.message_json.text} */}
                  
                      {/* <RecentList
                      image={this.getTypeUrl(data.message_json.type)}
                      iconWidth={"20px"}
                      name={data.contact ? data.contact.phone_number : ""} 
                      message={""}
                      time={""}
                      style={{ color: "#fff" }}
                      // dialNumber={callProcess}
                      // callInfo={data}
                      /> */}
                      <MessageInputAudio
                        // className={"chat-bubble chat-bubble--right"}
                        // bgcolor="#74b9ff"
                        // marginLeft="337px"
                        src={
                          `${data.message_json.record_url}`
                        }
                      />
                  
                </div>
                <div className="chat-last-seen--left">
                    <small>{this.renderTimestamp(data.time)}</small>
                    
                    
              </div>
            </div>
          </div>
            
          )}

          {data && data.type == "audio" && (
            <MessageInputAudio
              className={"chat-bubble chat-bubble--left"}
              src={
                "http://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Sevish_-__nbsp_.mp3"
              }
            />
          )}

          {data && data.type == "location" && (
            <MessageInputLocation
              className={"chat-bubble chat-bubble--left"}
              latitude={data.location.lat}
              longatude={data.location.lng}
              // src={
              //   "http://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Sevish_-__nbsp_.mp3"
              // }
            />
          )}

          {data && data.type == "confirm" && (
            <MessageInputConfirm
              className={"chat-bubble chat-bubble--left"}
              question={data.question}
              confirm={data.confirm}
              cancel={data.cancel}

              // src={
              //   "http://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Sevish_-__nbsp_.mp3"
              // }
            />
          )}

          
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {data && data.message_json && (data.message_json.type === "text" || data.message_json.type === "reply") && (
            <div className="row" ref={(el) => { this.messagesEnd = el; }}>
              <div
                className="right-chat"
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  float: "right"
                }}
              >
                <div
                  className="chat-bubble chat-bubble--right"
                
                  style={{ 
                    // display: "flex",
                    width: "fit-content", 
                    wordWrap: "break-word",
                    maxWidth: "85%" ,
                    marginLeft: "auto"
                  }}
                >
                  {/* <CustomDropdown className="chat-quick-reply--right" 
                  rightAlign={true}
                  label="Select an option" 
                  option={"chatReplyOptions"} 
                  icon="expand_more"/> */}

                  {/* {data && data.message_json.text} */}
                  
                    {/* <span> */}
                      {/* {data.agent ? currentUser === data.agent.username ? 
                      <b style={{ color: "lightgreen" }}>You</b> : 
                      <b style={{ color: "orange" }}>{data.agent.full_name}</b> : data.bot ? <b style={{ color: "purple" }}>{data.bot.name}</b> : ""} */}
                      {data.message_json.text}
                    {/* </span> */}
                  
                </div>
                <div className="chat-last-seen--right">
                    {/* {data.channel === "rcs" && <img src={`/images/singleinboxicons/RCS.svg`} width={25} height={25} style={{ marginRight: "5px" }} />}
                    {data.channel === "whatsapp" && <img src={`/images/singleinboxicons/WhatsApp.svg`} width={25} height={25} style={{ marginRight: "5px" }} />}
                    {data.channel === "voice" && <img src={`/images/singleinboxicons/Voice_Call.svg`} width={25} height={25} style={{ marginRight: "5px" }} />} */}
                    <small>{this.renderTimestamp(data.time)}</small>
                    
                    <img src={ (messageEvent && messageEvent.message) &&
                    (messageEvent.message.message_id === data.message_id) ?
                      messageEvent.event === "FAILED" ?
                      `${process.env.PUBLIC_URL}/images/Failed.svg` : messageEvent.event === "SENT" ? 
                        `${process.env.PUBLIC_URL}/images/sent.svg` : messageEvent.event === "DELIVERED" ?
                        `${process.env.PUBLIC_URL}/images/delivered.svg` : messageEvent.event === "UNDELIVERED" ?
                        `${process.env.PUBLIC_URL}/images/failed.svg` : messageEvent.event === "READ" ?
                        `${process.env.PUBLIC_URL}/images/read.svg` : "" :
                      data.state === "FAILED" ?
                    `${process.env.PUBLIC_URL}/images/Failed.svg` : data.state === "SENT" ? 
                      `${process.env.PUBLIC_URL}/images/sent.svg` : data.state === "DELIVERED" ?
                      `${process.env.PUBLIC_URL}/images/delivered.svg` : data.state === "UNDELIVERED" ?
                      `${process.env.PUBLIC_URL}/images/failed.svg` : data.state === "READ" ?
                      `${process.env.PUBLIC_URL}/images/read.svg` : ""
                    } 
                    height="12.5px"
                    width="12.5px"
                    style={{ marginLeft: "5px" }}
                    />
                
                </div>
              </div>
            </div>
          )}

          {data && data.message_json && data.message_json.type === "image" && ( 
            <div className="row no-gutters" ref={(el) => { this.messagesEnd = el; }}>
            <div 
                className=" offset-md-9"
                style={{
                  marginLeft: "337px",
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  float: "right",
                }}
                ref={(el) => { this.messagesEnd = el; }}
                >
                <div
                  className="chat-bubble chat-bubble--right"
                
                  style={{ 
                    // display: "flex",
                    width: "fit-content", 
                    wordWrap: "break-word",
                    maxWidth: "100%" 
                  }}
                >
                  {/* <CustomDropdown className="chat-quick-reply--right" 
                  rightAlign={true}
                  label="Select an option" 
                  option={"chatReplyOptions"} 
                  icon="expand_more"/> */}

                  {/* {data && data.message_json.text} */}
                  
                    {/* <span> */}
                      {/* {data.agent ? currentUser === data.agent.username ? 
                      <b style={{ color: "lightgreen" }}>You</b> : 
                      <b style={{ color: "orange" }}>{data.agent.full_name}</b> : data.bot ? <b style={{ color: "purple" }}>{data.bot.name}</b> : ""} */}
                      <MessageInputImage
                        // className={"chat-bubble--right"}
                        src={data.message_json.url}
                        // bgcolor="#74b9ff"
                        
                      />
            
                    {/* </span> */}
                  
                </div>
                <div className="chat-last-seen--right">
                    {/* {data.channel === "rcs" && <img src={`/images/singleinboxicons/RCS.svg`} width={25} height={25} style={{ marginRight: "5px" }} />}
                    {data.channel === "whatsapp" && <img src={`/images/singleinboxicons/WhatsApp.svg`} width={25} height={25} style={{ marginRight: "5px" }} />}
                    {data.channel === "call" && <img src={`/images/singleinboxicons/Voice_Call.svg`} width={25} height={25} style={{ marginRight: "5px" }} />} */}
                    <small>{this.renderTimestamp(data.time)}</small>
                    
                    <img src={(messageEvent && messageEvent.message) &&
                    (messageEvent.message.message_id === data.message_id) ?
                      messageEvent.event === "FAILED" ?
                      `${process.env.PUBLIC_URL}/images/Failed.svg` : messageEvent.event === "SENT" ? 
                        `${process.env.PUBLIC_URL}/images/sent.svg` : messageEvent.event === "DELIVERED" ?
                        `${process.env.PUBLIC_URL}/images/delivered.svg` : messageEvent.event === "UNDELIVERED" ?
                        `${process.env.PUBLIC_URL}/images/failed.svg` : messageEvent.event === "READ" ?
                        `${process.env.PUBLIC_URL}/images/read.svg` : "" :
                      data.state === "FAILED" ?
                    `${process.env.PUBLIC_URL}/images/Failed.svg` : data.state === "SENT" ? 
                      `${process.env.PUBLIC_URL}/images/sent.svg` : data.state === "DELIVERED" ?
                      `${process.env.PUBLIC_URL}/images/delivered.svg` : data.state === "UNDELIVERED" ?
                      `${process.env.PUBLIC_URL}/images/failed.svg` : data.state === "READ" ?
                      `${process.env.PUBLIC_URL}/images/read.svg` : ""
                    } 
                    height="12.5px"
                    width="12.5px"
                    style={{ marginLeft: "5px" }}
                    />
                
              </div>
            </div>
          </div>
          )}


          {data && data.message_json && data.message_json.type == "video" && (
          <div className="row no-gutters" ref={(el) => { this.messagesEnd = el; }}>
            <div 
                className=" offset-md-9"
                style={{
                  marginLeft: "337px",
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  float: "right",
                }}
                >
                <div
                  className="chat-bubble chat-bubble--right"
                
                  style={{ 
                    // display: "flex",
                    width: "fit-content", 
                    wordWrap: "break-word",
                    maxWidth: "100%" 
                  }}
                >
                  {/* <CustomDropdown className="chat-quick-reply--right" 
                  rightAlign={true}
                  label="Select an option" 
                  option={"chatReplyOptions"} 
                  icon="expand_more"/> */}

                  {/* {data && data.message_json.text} */}
                  
                    {/* <span> */}
                      {/* {data.agent ? currentUser === data.agent.username ? 
                      <b style={{ color: "lightgreen" }}>You</b> : 
                      <b style={{ color: "orange" }}>{data.agent.full_name}</b> : data.bot ? <b style={{ color: "purple" }}>{data.bot.name}</b> : ""} */}
                      <MessageInputVideo 
                        mimeType={data.message_json.mime_type}
                        src={data.message_json.url}
                        fileName={data.message_json.file_name}
                        // className={"chat-bubble--right"} 
                        />
          
                    {/* </span> */}
                  
                </div>
                <div className="chat-last-seen--right">
                    {/* {data.channel === "rcs" && <img src={`/images/singleinboxicons/RCS.svg`} width={25} height={25} style={{ marginRight: "5px" }} />}
                    {data.channel === "whatsapp" && <img src={`/images/singleinboxicons/WhatsApp.svg`} width={25} height={25} style={{ marginRight: "5px" }} />}
                    {data.channel === "voice" && <img src={`/images/singleinboxicons/Voice_Call.svg`} width={25} height={25} style={{ marginRight: "5px" }} />} */}
                    <small>{this.renderTimestamp(data.time)}</small>
                    
                    <img src={(messageEvent && messageEvent.message) &&
                    (messageEvent.message.message_id === data.message_id) ?
                      messageEvent.event === "FAILED" ?
                      `${process.env.PUBLIC_URL}/images/Failed.svg` : messageEvent.event === "SENT" ? 
                        `${process.env.PUBLIC_URL}/images/sent.svg` : messageEvent.event === "DELIVERED" ?
                        `${process.env.PUBLIC_URL}/images/delivered.svg` : messageEvent.event === "UNDELIVERED" ?
                        `${process.env.PUBLIC_URL}/images/failed.svg` : messageEvent.event === "READ" ?
                        `${process.env.PUBLIC_URL}/images/read.svg` : "" :
                      data.state === "FAILED" ?
                    `${process.env.PUBLIC_URL}/images/Failed.svg` : data.state === "SENT" ? 
                      `${process.env.PUBLIC_URL}/images/sent.svg` : data.state === "DELIVERED" ?
                      `${process.env.PUBLIC_URL}/images/delivered.svg` : data.state === "UNDELIVERED" ?
                      `${process.env.PUBLIC_URL}/images/failed.svg` : data.state === "READ" ?
                      `${process.env.PUBLIC_URL}/images/read.svg` : ""
                    } 
                    height="12.5px"
                    width="12.5px"
                    style={{ marginLeft: "5px" }}
                    />
                
              </div>
            </div>
          </div>


          )}

          {data && data.type == "link" && (
            <MessageInputLink
              bgcolor="#74b9ff"
              marginLeft="337px"
              className={"chat-bubble chat-bubble--right"}
              url={"http://www.astiqsolution.com/"}
              text={"DON"}
              styleData={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            />
          )}

          {data && data.message_json && data.message_json.type === "document" && ( 
          <div className="row no-gutters" ref={(el) => { this.messagesEnd = el; }}>
          <div 
              className=" offset-md-9"
              style={{
                marginLeft: "337px",
                marginBottom: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                float: "right",
              }}
              >
              <div
                className="chat-bubble chat-bubble--right"
              
                style={{ 
                  // display: "flex",
                  width: "fit-content", 
                  wordWrap: "break-word",
                  maxWidth: "100%" 
                }}
              >
                {/* <CustomDropdown className="chat-quick-reply--right" 
                rightAlign={true}
                label="Select an option" 
                option={"chatReplyOptions"} 
                icon="expand_more"/> */}

                {/* {data && data.message_json.text} */}
                
                  {/* <span> */}
                    {/* {data.agent ? currentUser === data.agent.username ? 
                    <b style={{ color: "lightgreen" }}>You</b> : 
                    <b style={{ color: "orange" }}>{data.agent.full_name}</b> : data.bot ? <b style={{ color: "purple" }}>{data.bot.name}</b> : ""} */}
                    <MessageInputDocuments
                    src={data.message_json.url}
                    fileName={data.message_json.file_name}
                    // className={"chat-bubble chat-bubble--right"}
                    
                    />
          
                  {/* </span> */}
                
              </div>
              <div className="chat-last-seen--right">
                  {/* {data.channel === "rcs" && <img src={`/images/singleinboxicons/RCS.svg`} width={25} height={25} style={{ marginRight: "5px" }} />}
                  {data.channel === "whatsapp" && <img src={`/images/singleinboxicons/WhatsApp.svg`} width={25} height={25} style={{ marginRight: "5px" }} />}
                  {data.channel === "voice" && <img src={`/images/singleinboxicons/Voice_Call.svg`} width={25} height={25} style={{ marginRight: "5px" }} />} */}
                  <small>{this.renderTimestamp(data.time)}</small>
                  
                  <img src={(messageEvent && messageEvent.message) &&
                    (messageEvent.message.message_id === data.message_id) ?
                      messageEvent.event === "FAILED" ?
                      `${process.env.PUBLIC_URL}/images/Failed.svg` : messageEvent.event === "SENT" ? 
                        `${process.env.PUBLIC_URL}/images/sent.svg` : messageEvent.event === "DELIVERED" ?
                        `${process.env.PUBLIC_URL}/images/delivered.svg` : messageEvent.event === "UNDELIVERED" ?
                        `${process.env.PUBLIC_URL}/images/failed.svg` : messageEvent.event === "READ" ?
                        `${process.env.PUBLIC_URL}/images/read.svg` : "" :
                      data.state === "FAILED" ?
                    `${process.env.PUBLIC_URL}/images/Failed.svg` : data.state === "SENT" ? 
                      `${process.env.PUBLIC_URL}/images/sent.svg` : data.state === "DELIVERED" ?
                      `${process.env.PUBLIC_URL}/images/delivered.svg` : data.state === "UNDELIVERED" ?
                      `${process.env.PUBLIC_URL}/images/failed.svg` : data.state === "READ" ?
                      `${process.env.PUBLIC_URL}/images/read.svg` : ""
                  } 
                  height="12.5px"
                  width="12.5px"
                  style={{ marginLeft: "5px" }}
                  />
              
            </div>
          </div>
        </div>
          
        )}

          {data && data.message_json && ["manual_call","auto_call","inbound_call"].includes(data.message_json.type) && (
            <div className="row no-gutters" ref={(el) => { this.messagesEnd = el; }}>
            <div 
                className=" offset-md-9"
                style={{
                  marginLeft: "337px",
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  float: "right",
                }}
                >
                <div
                  className="chat-bubble chat-bubble--right"
                
                  style={{ 
                    // display: "flex",
                    width: "fit-content", 
                    wordWrap: "break-word",
                    maxWidth: "100%" 
                  }}
                >
                  {/* <CustomDropdown className="chat-quick-reply--right" 
                  rightAlign={true}
                  label="Select an option" 
                  option={"chatReplyOptions"} 
                  icon="expand_more"/> */}
  
                  {/* {data && data.message_json.text} */}
                  
                    {/* <span> */}
                      {/* {data.agent ? currentUser === data.agent.username ? 
                      <b style={{ color: "lightgreen" }}>You</b> : 
                      <b style={{ color: "orange" }}>{data.agent.full_name}</b> : data.bot ? <b style={{ color: "purple" }}>{data.bot.name}</b> : ""} */}
                      {/* <RecentList
                      image={this.getTypeUrl(data.message_json.type)}
                      iconWidth={"20px"}
                      name={data.contact ? data.contact.phone_number : ""} 
                      message={""}
                      time={""}
                      style={{ color: "#fff" }}
                      // dialNumber={callProcess}
                      // callInfo={data}
                      /> */}
                      <MessageInputAudio
                        // className={"chat-bubble chat-bubble--right"}
                        // bgcolor="#74b9ff"
                        // marginLeft="337px"
                        src={
                          `${data.message_json.record_url}`
                        }
                        // styleData={{
                        //   display: "flex",
                        //   justifyContent: "flex-end",
                        // }}
                      />
                    {/* </span> */}
                  
                </div>
                <div className="chat-last-seen--right">
                    {/* {data.channel === "rcs" && <img src={`/images/singleinboxicons/RCS.svg`} width={25} height={25} style={{ marginRight: "5px" }} />}
                    {data.channel === "whatsapp" && <img src={`/images/singleinboxicons/WhatsApp.svg`} width={25} height={25} style={{ marginRight: "5px" }} />}
                    {data.channel === "voice" && <img src={`/images/singleinboxicons/Voice_Call.svg`} width={25} height={25} style={{ marginRight: "5px" }} />} */}
                    <small>{this.renderTimestamp(data.time)}</small>
                    
                    
              </div>
            </div>
          </div>
            
          )}
          {data && data.type == "location" && (
            <MessageInputLocation
              className={"chat-bubble chat-bubble--right"}
              bgcolor="#74b9ff"
              marginLeft="337px"
              styleData={{
                display: "flex",
                justifyContent: "flex-end",
              }}

              // src={
              //   "http://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Sevish_-__nbsp_.mp3"
              // }
            />
          )}

          {data && data.type == "confirm" && (
            <MessageInputConfirm
              className={"chat-bubble chat-bubble--right"}
              bgcolor="#74b9ff"
              marginLeft="337px"
              question={data.question}
              confirm={data.confirm}
              cancel={data.cancel}
              styleData={{
                display: "flex",
                justifyContent: "flex-end",
              }}

              // src={
              //   "http://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Sevish_-__nbsp_.mp3"
              // }
            />
          )}

          {data && data.message_json && data.message_json.type === "interactive" && (
            <div className="row no-gutters" ref={(el) => { this.messagesEnd = el; }}>
              <div
                className=" offset-md-9"
                style={{
                  marginLeft: "337px",
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  float: "right"
                }}
              >
                <div
                  className="chat-bubble chat-bubble--right"
                
                  style={{ 
                    // display: "flex",
                    width: "fit-content", 
                    wordWrap: "break-word",
                    maxWidth: "100%" 
                  }}
                >
                  {/* <CustomDropdown className="chat-quick-reply--right" 
                  rightAlign={true}
                  label="Select an option" 
                  option={"chatReplyOptions"} 
                  icon="expand_more"/> */}

                  {/* {data && data.message_json.text} */}
                  
                    {/* <span> */}
                      {/* {data.agent ? currentUser === data.agent.username ? 
                      <b style={{ color: "lightgreen" }}>You</b> : 
                      <b style={{ color: "orange" }}>{data.agent.full_name}</b> : data.bot ? <b style={{ color: "purple" }}>{data.bot.name}</b> : ""} */}
                      
                      {data.message_json.message ?
                        data.message_json.message.type === "buttons" ? <React.Fragment>
                          {data.message_json.message.body && data.message_json.message.body.text}
                          <span style={{ fontSize: "12px", marginLeft: "auto", color: "lightgrey" }}>
                            {data.message_json.message.footer && data.message_json.message.footer.text}
                          </span>
                        </React.Fragment> : data.message_json.message.text && data.message_json.message.text :
                        null
                      }
                      
                    {/* </span> */}
                  
                </div>
                <div className="chat-last-seen--right">
                    {/* {data.channel === "rcs" && <img src={`/images/singleinboxicons/RCS.svg`} width={25} height={25} style={{ marginRight: "5px" }} />}
                    {data.channel === "whatsapp" && <img src={`/images/singleinboxicons/WhatsApp.svg`} width={25} height={25} style={{ marginRight: "5px" }} />}
                    {data.channel === "voice" && <img src={`/images/singleinboxicons/Voice_Call.svg`} width={25} height={25} style={{ marginRight: "5px" }} />} */}
                    <small>{this.renderTimestamp(data.time)}</small>
                    
                    <img src={ (messageEvent && messageEvent.message) &&
                    (messageEvent.message.message_id === data.message_id) ?
                      messageEvent.event === "FAILED" ?
                      `${process.env.PUBLIC_URL}/images/Failed.svg` : messageEvent.event === "SENT" ? 
                        `${process.env.PUBLIC_URL}/images/sent.svg` : messageEvent.event === "DELIVERED" ?
                        `${process.env.PUBLIC_URL}/images/delivered.svg` : messageEvent.event === "UNDELIVERED" ?
                        `${process.env.PUBLIC_URL}/images/failed.svg` : messageEvent.event === "READ" ?
                        `${process.env.PUBLIC_URL}/images/read.svg` : "" :
                      data.state === "FAILED" ?
                    `${process.env.PUBLIC_URL}/images/Failed.svg` : data.state === "SENT" ? 
                      `${process.env.PUBLIC_URL}/images/sent.svg` : data.state === "DELIVERED" ?
                      `${process.env.PUBLIC_URL}/images/delivered.svg` : data.state === "UNDELIVERED" ?
                      `${process.env.PUBLIC_URL}/images/failed.svg` : data.state === "READ" ?
                      `${process.env.PUBLIC_URL}/images/read.svg` : ""
                    } 
                    height="12.5px"
                    width="12.5px"
                    style={{ marginLeft: "5px" }}
                    />
                
                </div>
              </div>
            </div>
          )}

        </React.Fragment>
      );
    }
  }
}


// const stateToProps = (state) => ({
//   // inputMessage: chatAppSelector.getChatInputMessage(state),
//   chatMessagesList: chatAppSelector.getChatMessagesList(state),
//   messageEvent: chatAppSelector.getMessageEvent(state),
//   selectedUser: chatAppSelector.getSelectedUser(state),

// });

export default MsgComponent;
