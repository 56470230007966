import React from "react";
import ContactHome from "./components/ContactHome";
import IMAGES from "../../utils/Images";
// import { HeroContainer, MainContainer } from "../assets/styles/theme/styles";

export default function Enquiry() {
    const description = "Complete the form to receive assistance with the registration process for Google RCS.";

  return (
    <>
      {/* <HeroContainer imageUrl={IMAGES.bg1} bgSize="cover"> */}
      <ContactHome heading={`Contact Us!`} rightImg={IMAGES.contact_us} formType="enquiry" description={description} />

      {/* </HeroContainer> */}
      {/* <MainContainer>
        <Mapgoogle />
      </MainContainer> */}
    </>
  );
}
