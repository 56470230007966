import React, { Component } from "react";
// import "./style.css";

export default class MessageInputVideo extends Component {
  render() {
    let {
      className,
      bgcolor = "",
      marginLeft = "",
      src,
      fileName="",
      styleData,
      mimeType="video/ogg"
    } = this.props;
    return (
      // <div style={styleData}>
        // <div
        //   class={className}
        //   style={{
        //     display: "flex",
        //     width: "339px",
        //     height: "194px",
        //     alignItems: "center",
        //     justifyContent: "center",
        //     // marginLeft: "45px",
        //     borderRadius: "11px",
        //     padding: "0px",
        //     backgroundColor: bgcolor ? bgcolor : "",
        //     marginLeft: marginLeft ? marginLeft : "",
        //   }}
        // >
          <video
            width="250px"
            height="auto"
            controls
            style={{ alignContent: "center", borderRadius: "11px" }}
          >
            <source src={src} type={mimeType} />
            Your browser does not support the video tag.
          </video>

        //   {/* src={src}
        //   alt="A beautiful landscape"
        //   width="330"
        //   height="186"
        //   style={{ alignContent: "center", borderRadius: "11px" }} */}
        // </div>
      // </div>
    );
  }
}
