import { Routes, Route, useLocation } from "react-router-dom";
import ContactUS from "./components/Pages/contact/ContactUS";
import Home from "./components/Pages/Home";
import { GlobalStyle } from "./assets/styles/globalStyles";
import ScrollToTop from "./components/utils/ScrollToTop";
import "./index.css";
import SignUp from "./components/signup/SignUp";
import Footer from "./components/footer/Footer";
import Helmet from "react-helmet";
import { PricingComponent } from "./components/PricingComponent";
import Licences from "./components/Licences";
import Header from "./components/Navbar/Header";
import PaymentSuccessPage from "./components/payment/PaymentSuccess";
import PaymentFailurePage from "./components/payment/PaymentFailure";
import Enquiry from "./components/Pages/contact/Enquiry";


export default function App() {
  const location = useLocation();

  return (
    <div>
      <Helmet>
        <title>1inbox - Connect,Engage,Enhance</title>
      </Helmet>

      <GlobalStyle />
      {location.pathname === "/sign-up" ? (
        <SignUp />
      ) : (
        <>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Home />} />
            <Route path="/contact-us" element={<ContactUS />} />
            <Route path="/pricing" element={<PricingComponent />} />
            <Route path="/licences" element={<Licences />} />
            <Route path="/payment-success" element={<PaymentSuccessPage />} />
            <Route path="/payment-failed" element={<PaymentFailurePage />} />
            <Route path="/enquiry" element={<Enquiry />} />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
}
