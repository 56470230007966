import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';



const PaymentSuccessContainer = styled.div`
  margin-top:40px !important;
  text-align: center;
  margin: 0 auto;
  max-width: 600px;
  padding: 30px;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 20px;
  box-shadow: #525be8 0px 0px 26px -15px;
  
  >b {
    font-size: 14px;
  }
`;

const PaymentSuccessHeading = styled.h1`
  color: #27ae60;
  font-size:25px;
  font-weight:500px;
`;

const PaymentSuccessText = styled.p`
  margin: 10px 0;
  font-size: 18px;
`;

const Message = styled.p`
  margin: 10px 0;
  font-size: 16px;
  font-weight: 600;
`;

const HomeLink = styled.a`
  display: block;
  margin-top: 20px;
  text-decoration: underline;
  color: #3498db;
  cursor: pointer;
`;

const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(5)

  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else {
      window.location.href = 'https://portal.1inbox.ai/';
    }

    return(() => {
      clearInterval(intervalId)
    })

  }, [timer])

    return (
        <PaymentSuccessContainer>
            <PaymentSuccessHeading>Payment Successful!</PaymentSuccessHeading>
            <PaymentSuccessText>Your payment has been processed successfully. Thank you for your purchase!</PaymentSuccessText>
            <Message>Your credentials have been sent to your registered email id</Message>

            <br />
            <b>Redirecting you to login page in {timer} seconds...</b>
            {/* <PaymentSuccessText>Order ID: ABC123</PaymentSuccessText> */}
            <HomeLink onClick={() => {
              // navigate('/');
              window.location.href = 'https://portal.1inbox.ai/';
                // window.close();
                // // Close the window and send a message to the parent window
                // if(window.opener) {
                //   window.opener.postMessage('paymentSuccess', '*');
                // }
            }}>Go to Login</HomeLink>
        </PaymentSuccessContainer>
    );
}

export default PaymentSuccessPage;
