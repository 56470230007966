import React from "react";
import ContactHome from "./components/ContactHome";
import IMAGES from "../../utils/Images";
// import { HeroContainer, MainContainer } from "../assets/styles/theme/styles";

export default function ContactUS() {
  return (
    <>
      {/* <HeroContainer imageUrl={IMAGES.bg1} bgSize="cover"> */}
      <ContactHome heading={`Get in Touch!`} rightImg={IMAGES.contact_us} formType="" />

      {/* </HeroContainer> */}
      {/* <MainContainer>
        <Mapgoogle />
      </MainContainer> */}
    </>
  );
}
