const Images = {
  company_logo: require("../../assets/loogoo.png"),
  contact_us: require("../../assets/images/Contactus.png"),
  home: require("../../assets/images/we.webp"),
  paySpeaker: require("../../assets/images/insi.webp"),
  home2: require("../../assets/on.webp"),
  home3: require("../../assets/images/home2.png"),
  icon1: require("../../assets/images/Icon 1.png"),
  icon2: require("../../assets/images/Icon 2.png"),
  icon3: require("../../assets/images/Icon 3.png"),
  Tube: require("../../assets/images/tube.png"),
  cross: require("../../assets/images/cross.png"),
  check: require("../../assets/images/check.png"),
  blue: require("../../assets/BluePatch.png"),
  line2: require("../../assets/images/31.png"),
  line1: require("../../assets/images/32.png"),
  video: require("../../assets/images/vid.mp4"),
  recom: require("../../assets/terms/flass.png"),
  flages: require("../../assets/terms/Flag2.png"),
  retail: require("../../assets/images/Retail service.jpg"),
  healthcare: require("../../assets/images/healthcare.jpg"),
  tele: require("../../assets/images/Telecommunication.jpg"),
  travel: require("../../assets/images/Travel & Tourism.jpg"),
  auto: require("../../assets/images/Automotive.jpg"),
  edu: require("../../assets/images/Education.jpg"),
  cus: require("../../assets/images/Customer Support.jpg"),
  sales: require("../../assets/images/Sales.jpg"),
  mar: require("../../assets/images/Operations.jpg"),
  opr: require("../../assets/images/healthcare.jpg"),
  com: require("../../assets/images/Compliance and Record-keeping.jpg"),
  multi: require("../../assets/images/Multi-Channel Support Teams.jpg"),
  hum: require("../../assets/images/Human resource.jpg"),
  sup: require("../../assets/images/Supply Chain Management.jpg"),
  qua: require("../../assets/images/Quality Assurance.jpg"),
  ec: require("../../assets/images/ecommerce.jpg"),
  fc: require("../../assets/images/Financial services.jpg"),
  ho: require("../../assets/images/Hospitality.jpg"),
  unli: require("../../assets/terms/Flag3 (1).png"),
  onboardSuccess: require("../../assets/check-mark.png"),






};

export default Images;
