import { Component } from 'react';
import { AiOutlineMessage } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import WebSocketInstance from '../../../websocket/singleInbox';
import MsgComponent from './MsgComponent';
import { BsSendFill } from "react-icons/bs";

var uuid = require('uuid');

export class Widget extends Component {

    constructor(props) {

        super(props);

        this.state = {
            senderId : uuid.v4(),
            inputValue: "",
            showChatBox: false,
            widgetFirstClicked: 1,
            contactId: sessionStorage.getItem("contactId") || null,
            chatMessagesList: [],
            isLastMessageId: false,
            showNotification: false, 
            messageCount: 0 
        }

        // if(this.state.showChatBox) {
            this.waitForSocketConnection(() => {
                WebSocketInstance.addCallbacks(this.setMessages.bind(this), this.addMessage.bind(this), this.setMessageEvent.bind(this))
                // WebSocketInstance.fetchMessages();
              });
        // }
        
      }
  
      waitForSocketConnection(callback) {
          const component = this;
          setTimeout(
              function () {
              if (WebSocketInstance.state() === 1) {
                  console.log("Connection is made")
                  callback();
                  return;
              } else {
                  console.log("wait for connection...")
                  component.waitForSocketConnection(callback);
              }
          }, 3000);
      }
  
      handleNotification(parsedData) {
        this.musicPlay();
        if (Notification.permission === 'granted') {

            const notification = new Notification(`${parsedData && parsedData.agent ?
              parsedData.agent.full_name || parsedData.agent.username : "New Message"}`, {
              body: `${parsedData && parsedData.message_json ? parsedData.message_json.text || parsedData.message.message_json.type : ""}`,
              icon: `${process.env.REACT_APP_HOSTNAME}/images/TLight.png`
            });
  
            // notification.onclick = function(event) {
            //   event.preventDefault();
            //   window.focus(`${process.env.REACT_APP_HOSTNAME}/chat-app`);
            // }
  
        } else if (Notification.permission !== 'denied') {
  
            Notification.requestPermission().then((permission) => {
              if (permission === 'granted') {
  
                const notification = new Notification(`${parsedData && parsedData.agent ?
                    parsedData.agent.full_name || parsedData.agent.username : "New Message"}`, {
                    body: `${parsedData && parsedData.message_json ? parsedData.message_json.text || parsedData.message.message_json.type : ""}`,
                    icon: `${process.env.REACT_APP_HOSTNAME}/images/TLight.png`
                  });
        
                // notification.onclick = function(event) {
                //   event.preventDefault();
                //   window.focus(`${process.env.REACT_APP_HOSTNAME}/chat-app`);
                // }
              }
            });
        }
         else {
          // Show custom HTML/CSS modal
          const modal = document.createElement('div');
          modal.classList.add('modal');
          modal.innerHTML = `
            <div class="modal-content">
              <h2>New message</h2>
              <p>${parsedData && parsedData.agent ? parsedData.agent.full_name || parsedData.agent.username : ""}
              <br /> ${parsedData && parsedData.message_json ? parsedData.message_json.text || "new message" : ""}</p>
            </div>
          `;
          document.body.appendChild(modal);
        }
      }

      addMessage(message) {
        let messages = [...this.state.chatMessagesList, message];

        if(message.sender === "agent" && this.state.showChatBox) {
            
            if(!this.state.showChatBox) {
                this.handleNotification(message);
            }
            
            WebSocketInstance.chatMessageEvent({
              channel: message.channel,
              event: "READ",
              phone_number: message.contact.phone_number || message.contact.contact_id,
              contact_id: message.contact.contact_id,
              message_id: message.message_id
            })

            // message.message.state = "READ"   
        }

        this.setState({ chatMessagesList: [...this.state.chatMessagesList, message], messageCount: messages.length > 3 ? 0 : 2, showNotification: messages.length > 3 ? false : true});
        
      }
  
      setMessageEvent(event) {
        if(this.state.chatMessagesList.length) {
            // get message from list of message and update message event
            let messageIndex = this.state.chatMessagesList.findIndex(msgObj => msgObj.message_id === event.message.message_id);
            
            if(messageIndex > -1) {
              let chatMessageArr = [...this.state.chatMessagesList];
              chatMessageArr[messageIndex].state = event.event;

              this.setState({
                chatMessagesList: [...chatMessageArr]
              });
            }
        }
      }

      setMessages(messages) {

        if(!messages.length) {
            this.generateRequest("hi");
        }
        
        // when scrolled on top
        else if(this.state.isLastMessageId) {
            this.setState({ chatMessagesList: [...messages, ...this.state.chatMessagesList]});
        } else {
            this.setState({ chatMessagesList: messages, messageCount: messages.length > 3 ? 0 : 2, showNotification: messages.length > 3 ? false : true});
        }

      }

      // notification play
  musicPlay() {
    console.log(`[Notification] Play`);
    try {
      const audio = new Audio(`https://${process.env.REACT_APP_HOSTNAME}/sound/notify.mp3`);
      audio.muted = false;
      audio.play();

    } catch (error) {
      console.log(`[Notification] Play Error`, error);
    }
  }


    gitInitialMessage = (senderId) => {
        if(WebSocketInstance.state() === 1) {
            this.waitForSocketConnection(() => {
                console.log("called =>");
                WebSocketInstance.fetchMessages();
                WebSocketInstance.addCallbacks(this.setMessages.bind(this), this.addMessage.bind(this), this.setMessageEvent.bind(this));
                
            });
            if(this.state.chatMessagesList.length) {
                WebSocketInstance.chatMessageEvent({
                    channel: "web",
                    event: "READ",
                    contact_id: this.state.contactId,
                    message_id: this.state.chatMessagesList.at(-1).message_id
                }); 
            } 
            // else {
            //     this.generateRequest("hi");
            // }
        }
        else {
            if(senderId) {
                WebSocketInstance.connect(senderId);
                this.waitForSocketConnection(() => {
                    WebSocketInstance.fetchMessages();
                    console.log("called =>");
                    // if(!this.state.chatMessagesList.length) {
                    // } else {
                    // }
                    WebSocketInstance.addCallbacks(this.setMessages.bind(this), this.addMessage.bind(this), this.setMessageEvent.bind(this))
                }); 
            }
            
        }
          
    };
    

    generateResposne = (response) => {
        const element = document.getElementById("chat_body");
        
            for(let res=0; res < response.length; res++) {
                const para = document.createElement("p");  
                para.id= "left-message";
                const node = document.createTextNode(response[res]?.text);
                // const br = document.createElement("br");    
    
                para.appendChild(node);
    
                console.log(response[res]);
        
                element.appendChild(para);
                // element.appendChild(br);
        
                if(response[res].buttons) {
                    console.log("response[res].buttons", response[res].buttons);
    
                    for(let btn=0; btn<response[res].buttons.length; btn++) {
        
                        const button = document.createElement("button");    
                        const btnText = document.createTextNode(response[res].buttons[btn].title);
                        
                        button.className = "response_btn";
                        button.value = response[res].buttons[btn].payload;
    
                        console.log("btnText",typeof btnText);
    
                        button.addEventListener("click", () => {
                            this.generateRequest(response[res].buttons[btn].title);
                            this.gitInitialMessage(this.props.domProps?.senderId || 6041);
                        }, false);
            
                        
                        button.appendChild(btnText);
                        console.log(button)
                        element.appendChild(button);
                    }
            
                }
                    
            }
        
        console.log(element);
        
    }


    generateRequest = (request) => {
            const messageObject = {
                // from: currentUser.email,
                content: {type: "text", text: request},
                channel: "web",
                phone_number: this.state.contactId
            };
        
            // this.waitForSocketConnection(() => {
            //     console.log("called =>")
                WebSocketInstance.newChatMessage(messageObject);
                this.setState({ inputValue: "" })
            // })    
            
        
        
    }


    handleScrollToTop() {
        let last_message_id = (this.state.chatMessagesList && 
          this.state.chatMessagesList.length) ? this.state.chatMessagesList[0].id : null;
    
        if (this.messagesEnd && this.messagesEnd.scrollTop === 0 && 
          (this.state.chatMessagesList.length >= 20 && last_message_id)) {
    
          console.log('Fetch more list items!');
          this.setState({ isLastMessageId: true });
          WebSocketInstance.fetchMessages(last_message_id);
            
        }
        // else {
        //   this.setState({ isLastMessageId: false });
        // }
        console.log("handleScrollToTop called");
      }

      
    handleSubmitBtn= (e) => {
        e.preventDefault();
        if(this.state.inputValue.length) {
            this.generateRequest(this.state.inputValue);
            // this.gitInitialMessage({
            //     message:this.state.inputValue,
            //     sender: `${this.state.senderId}`
            // })
        }
    }

    componentDidMount() {
        this.gitInitialMessage(this.props.domProps?.senderId || 6041);
    }

    // notification play
    musicPlay(){
        try {
            const audio = new Audio(`${process.env.REACT_APP_HOSTNAME}/sound/notify.mp3`);
            audio.muted = false;
            audio.play();
            
        } catch (error) {
        console.log(`[Notification] Play Error`, error);
        }
    }

    render() {
        
        let { chatMessagesList } = this.state;

        return (
        // <WidgetParentDiv>
        <>
            <button className="widget_button" 
                onClick={() => {
                    this.setState({ showChatBox: !this.state.showChatBox, widgetFirstClicked: 1, showNotification: false });
                    document.getElementById("chat_box").style.display = !this.state.showChatBox ? "block" : "none";
                    if(window.innerWidth <= 768) {
                        document.getElementsByClassName("widget_button")[0].style.display = !this.state.showChatBox ? "none" : "block";
                    }
                }}>
                {!this.state.showChatBox ? 
                    <AiOutlineMessage size={36} style={{ verticalAlign: "text-top" }}/> : <MdOutlineClose size={36} style={{ verticalAlign: "text-top" }} />}
                {/* <i className="fa fa-message 2fx"></i> :
                <i className="fa fa-close 2fx"></i>} */}
                {(this.state.showNotification && (this.state.messageCount || !this.state.contactId)) ? <div className='notification-count'>{this.state.messageCount}</div> : null}
            </button>

            {/* {this.state.showChatBox &&  */} 
            <div id="chat_box">
                <div className="chat_header">
                    <p className='header_title'>{this.props.domProps?.title || "Chat"}
                    <MdOutlineClose size={32} 
                    style={{ float: "right" }}

                    onClick={() => {
                        this.setState({ showChatBox: !this.state.showChatBox });
                        document.getElementById("chat_box").style.display = !this.state.showChatBox ? "block" : "none";
                        document.getElementsByClassName("widget_button")[0].style.display = !this.state.showChatBox ? "none" : "block";
                    }}
                    />
                    </p>
                </div>
                <div id="chat_body" 
                ref={(el) => { this.messagesEnd = el; }}
                onScroll={this.handleScrollToTop.bind(this)}>
                {chatMessagesList && chatMessagesList.length ?
                chatMessagesList.slice(1).map((msg, index) => {
                    if (msg.sender && (msg.sender === "agent" || msg.sender === "bot")) {
                    return <MsgComponent data={msg} place="left" key={index} />;
                    } else {
                    return <>
                        <MsgComponent data={msg} 
                        place="right"
                        key={index}
                        />
                        
                    </>;
                    }
                }) : 
                <div 
                style={{ 
                    display: "flex", 
                    marginTop: "25%",
                    justifyContent: "center" }}>
                    <p>Type message to start conversation!</p>
                </div> }
                    
                </div>
                <form className="chat_footer" onSubmit={this.handleSubmitBtn}>
                    <input type="text" id="customer_message"
                    placeholder="Type your message" 
                    value={this.state.inputValue}
                    onChange={(e) => {
                        this.setState({ inputValue: e.target.value});
                        if(this.timer) clearTimeout(this.timer);
                        this.timer = setTimeout(() => {
                            WebSocketInstance.chatMessageEvent({
                                channel: "web",
                                event: "IS_TYPING",
                                contact_id: this.state.contactId,
                                message_id: null
                            });
                        }, 2000)
                        
                    }}/>
                    <button type='submit'
                    className="send_btn" disabled={this.state.inputValue === ""}
                    >
                        <BsSendFill />
                    </button>
                </form>
            </div>
            {/* } */}
        </>
        // </WidgetParentDiv>
        );
    }
}
