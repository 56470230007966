import React, { useState } from "react";
import {
  BsCaretDownFill,
  BsCaretUpFill,
  BsCaretRightFill,
} from "react-icons/bs";
import styled from "styled-components";
import theme from "../../assets/styles/theme/theme";

const AccordionList = () => {
  const Data = [
    {
      title: "Scalability "+ String.fromCharCode(160)+String.fromCharCode(160)+String.fromCharCode(160)+String.fromCharCode(160)+String.fromCharCode(160)+String.fromCharCode(160)+String.fromCharCode(160),
      description:
        "1iNbox adapts to the growing needs of brands, making it suitable for businesses of all sizes.",
    },
    {
      title: "Cost Efficiency"+String.fromCharCode(160)+String.fromCharCode(160)+String.fromCharCode(160),
      description:
        "By streamlining operations and reducing the need for multiple tools, brands can lower operational costs.",
    },
    {
      title: "24/7 Availability"+ String.fromCharCode(160)+String.fromCharCode(160)+String.fromCharCode(160)+String.fromCharCode(160) ,
      description:
        "Brands can provide round-the-clock customer support by utilizing automation and chatbots within the platform.",
    },
    {
      title: "Data Driven Insights",
      description:
        "1iNbox offers valuable data analytics, enabling brands to understand customer behaviour and preferences better, aiding in strategic decision-making.",
    },
    
    {
      title: "Consistent Branding"+ String.fromCharCode(160)+ String.fromCharCode(160)+String.fromCharCode(160),
      description:
        "Maintaining a consistent brand image across all communication channels becomes easier, ensuring a unified customer experience.",
    },
   
    {
      title: "Unified Customer View"+String.fromCharCode(160)+String.fromCharCode(160),
      description:
        "Brands can consolidate customer interactions from multiple channels into a single interface, providing agents with a holistic view of each customers communication history.",
    },
    {
      title: "Cross-Channel Support"+String.fromCharCode(160)+String.fromCharCode(160)+String.fromCharCode(160),
      description:
        "Agents can seamlessly switch between channels, using SMS and email to share necessary information with customers while maintaining the conversation context.",
    },

    {
      title: "Personalized Engagement"+String.fromCharCode(160)+String.fromCharCode(160)+String.fromCharCode(160),
      description:
        " Brands can use customer data from various channels to tailor responses and offers, making interactions more relevant and engaging.",
    },

    {
      title: "Multi-Channel Convenience"+String.fromCharCode(160)+String.fromCharCode(160) ,
      description:
        " Brands can communicate with customers on their preferred channels, including WhatsApp, Voice, RCS, web chat, and chatbots, all within one platform.",
    },

    {
      title: "Improved Agent Productivity"+ String.fromCharCode(160)+String.fromCharCode(160)+String.fromCharCode(160)+String.fromCharCode(160)+String.fromCharCode(160),
      description:
        "Agents save time by eliminating the need to switch between channels, allowing them to handle more inquiries and tasks effectively",
    },
    {
      title: "Enhanced Customer Satisfaction" ,
      description:
        "With seamless conversations and quicker responses, customer satisfaction increases, leading to improved brand loyalty.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "7px" }}>
      {Data.map((item, index) => (
        <div
          key={index}
          onClick={() => handleAccordionClick(index)}
          className="accordion-item"
        >
          <Label style={{ display: "flex", alignItems: "center" }}>
            <span>{item.title}</span>
            {openIndex === index ? (
              <BsCaretDownFill
                className="accordion-icon"
                style={{ color: "blue", marginLeft: "10px" }}
              />
            ) : (
              <BsCaretRightFill
                className="accordion-icon"
                style={{ color: "blue", marginLeft: "10px" }}
              />
            )}
          </Label>
          {openIndex === index && <Description>{item.description}</Description>}
        </div>
      ))}
    </div>
  );
};

export default AccordionList;

const Label = styled.h5`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: ${theme.colors.secondary};
`;

const Description = styled.p`
  color: ${theme.colors.lightgrey};
  font-size: ${theme.fontSizes.paragraph6};
  padding: 10px 0px;
`;
