import React from "react";
import Heading from "./Heading";
// import { MainContainer, FlexContainer } from "../assets/styles/theme/styles";
import styled from "styled-components";
import { FlexContainer, MainContainer } from "../../assets/styles/theme/styles";
import theme from "../../assets/styles/theme/theme";
// import Button from "./ Button";
// import LinkComponent from "./Link";
// import RedirectToPages from "./RedirectToPages";
// import BrowserCompatibility from "./BrowserCompatibility";

export default function Insights({
  heading,
  text,
  imageSrc,
  fgvideo,
  link,
  btn,
  drn,
  video,
}) {
  return (
    <>
      <MainContainer>
        <FlexContainer flxdrn={drn} gap="4rem">
          <TextContainer>
            <Heading level={1}>
              <h1
                style={{
                  fontWeight: "bold",
                  fontSize: "40px",
                  fontWeight: "600",
                }}
              >
                Insights{" "}
                <span style={{ fontSize: "25px" }}> - Data & Reports</span>
              </h1>{" "}
            </Heading>
            <p style={{ color: "#7d7c87" }}>
              <strong>Historical Data Trends :</strong> Explore historical data
              trends to identify patterns and plan for future initiatives.
              <br />
              <br />
              <p>
                <strong>Integration Capabilities :</strong> Showcase the
                platform's ability to integrate with other tools and systems for
                a seamless communication ecosystem.
              </p>
              <br />
              <p>
                <strong>Feedback CSAT :</strong> Establish feedback loops to
                collect user opinions and continuously improve the platform.
              </p>
              <br />
              <p>
                <strong>10+ Customizable Insights :</strong> Tailor the platform
                to your specific needs with a wide range of customizable
                insights.
              </p>
            </p>

            {/* {link && <LinkComponent text={`Learn more`} />} */}

            {/* {btn && (
              <BtnsWrapper>
                {btn.map((item) => (
                  <RedirectToPages href="contact-us">
                    <Button key={item} text={item} />
                  </RedirectToPages>
                ))}
              </BtnsWrapper>
            )} */}
          </TextContainer>
          <ImageColumn>
            {!video && <img src={imageSrc} alt="" />}
            {/* {video && (
              <BrowserCompatibility image={imageSrc} video={fgvideo} />
            )} */}
          </ImageColumn>
        </FlexContainer>
      </MainContainer>
    </>
  );
}

const TextContainer = styled.div`
  text-align: center;
  color: ${theme.colors.secondary};
  flex: 1;

  @media (min-width: 1020px) {
    text-align: left;
  }

  // > h1 {
  //   font-size: 2.5rem;
  //   margin-top: 24px;
  //   margin-bottom: 12px;
  //   color: black;
  // }

  > p {
    font-size: 17px;
    line-height: 30px;
    color: #5f627b;
  }
`;

const ImageColumn = styled.div`
  display: grid;
  place-items: center;
  flex: 1.1;

  > img {
    height: auto;
    width: 100%;
    max-width: 660px;
  }

  @media (min-width: 1020px) {
    > img {
      padding-right: 0;
    }

    
  }

  video {
    width: 100%;

    @media (min-width: 720px) {
      width: 580px;
    }
`;

const BtnsWrapper = styled.div`
  gap: 2rem;
  display: flex;
  justify-content: center;

  @media (min-width: 1020px) {
    justify-content: flex-start;
  }
`;
