import React from "react";
import Heading from "./Heading";
// import { MainContainer, FlexContainer } from "../assets/styles/theme/styles";
import styled from "styled-components";
import { FlexContainer, MainContainer } from "../../assets/styles/theme/styles";
import theme from "../../assets/styles/theme/theme";
// import Button from "./ Button";
// import LinkComponent from "./Link";
// import RedirectToPages from "./RedirectToPages";
// import BrowserCompatibility from "./BrowserCompatibility";

export default function ImageTextSection({
  heading,
  text,
  imageSrc,
  fgvideo,
  link,
  btn,
  drn,
  video,
}) {
  return (
    <>
      <MainContainer>
        <FlexContainer flxdrn={drn} gap={"9rem"}>
          <TextContainer>
            <h1>{heading}</h1>
            {text &&
              text.map((item, ind) => {
                return (
                  <>
                    <p key={ind}>{item}</p> <br />
                  </>
                );
              })}
            {/* {link && <LinkComponent text={`Learn more`} />} */}

            {/* {btn && (
              <BtnsWrapper>
                {btn.map((item) => (
                  <RedirectToPages href="contact-us">
                    <Button key={item} text={item} />
                  </RedirectToPages>
                ))}
              </BtnsWrapper>
            )} */}
          </TextContainer>
          <ImageColumn>
            {!video && <img src={imageSrc} alt="" />}
            {/* {video && (
              <BrowserCompatibility image={imageSrc} video={fgvideo} />
            )} */}
          </ImageColumn>
        </FlexContainer>
      </MainContainer>
    </>
  );
}

const TextContainer = styled.div`
  text-align: center;
  color: black;
  flex: 1.2;

  @media (min-width: 1020px) {
    text-align: left;
  }

  > h1 {
    font-size: ${theme.fontSizes.heading2};

    margin-bottom: 12px;
    color: ${theme.colors.secondary};
    font-weight: 600;

    @media (min-width: 1020px) {
      max-width: 480px;
      font-size: ${theme.fontSizes.heading1};
    }
  }

  > p {
    font-size: ${theme.fontSizes.paragraph3};
    line-height: 30px;
    color: ${theme.colors.lightgrey};
  }
`;

const ImageColumn = styled.div`
  display: grid;
  place-items: center;
  flex: 1.1;

  > img {
    height: auto;
    width: 100%;
    max-width: 550px;
  }

  @media (min-width: 1020px) {
    > img {
      padding-right: 0;
    }

    
  }

  video {
    width: 100%;

    @media (min-width: 720px) {
      width: 580px;
    }
`;

const BtnsWrapper = styled.div`
  gap: 2rem;
  display: flex;
  justify-content: center;

  @media (min-width: 1020px) {
    justify-content: flex-start;
  }
`;
