import React from "react";
import styled from "styled-components";
import theme from "../../assets/styles/theme/theme";

const Paragraph = ({ level, color, children, ...rest }) => {
  switch (level) {
    case 1:
      return (
        <P1 color={color} {...rest}>
          {children}
        </P1>
      );
    case 2:
      return (
        <P2 color={color} {...rest}>
          {children}
        </P2>
      );
    case 3:
      return (
        <P3 color={color} {...rest}>
          {children}
        </P3>
      );
    case 4:
      return (
        <P4 color={color} {...rest}>
          {children}
        </P4>
      );
    default:
      return null;
  }
};

export default Paragraph;

const commonStyles = `
  margin: 0;
  font-weight: normal;
  line-height: 1.6em;
  color: ${theme.colors.lightgrey};
  text-align: center;
  margin-bottom: 2rem;

  @media (min-width: 1020px) {
  //  text-align: left
  }
`;

const P1 = styled.p`
  ${commonStyles}
  font-size: ${theme.fontSizes.paragraph1};
  color: ${(props) => props.color || theme.colors.text};
`;

const P2 = styled.p`
  ${commonStyles}
  font-size: ${theme.fontSizes.paragraph2};
  color: ${(props) => props.color || theme.colors.text};
`;
const P3 = styled.p`
  ${commonStyles}
  font-size: ${theme.fontSizes.paragraph3};
  color: ${(props) => props.color || theme.colors.text};
`;

const P4 = styled.p`
  ${commonStyles}
  font-size: ${theme.fontSizes.paragraph4};
  color: ${(props) => props.color || theme.colors.text};
`;
