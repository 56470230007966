import React, { Component } from "react";

export default class MessageInputAudio extends Component {
  audioRef = React.createRef();

  togglePlay = () => {
    if (this.audioRef.current.paused) {
      this.audioRef.current.play();
    } else {
      this.audioRef.current.pause();
    }
  };

  handleTimelineChange = (event) => {
    // Set the current time of the audio to the value of the scroll bar
    this.audioRef.current.currentTime = event.target.value;
  };
  render() {
    let {
      className,
      bgcolor = "",
      marginLeft = "",
      src,
      url,
      text,
      styleData,
    } = this.props;
    // let audio = new Audio(
    //   "http://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Sevish_-__nbsp_.mp3"
    // );

    // const start = () => {
    //   audio.play();
    // };

    return (
      // <div style={styleData}>
      //   <diV
      //     class={className}
      //     style={{
      //       display: "flex",
      //       width: "339px",
      //       height: "85px",
      //       marginLeft: "45px",
      //       borderRadius: "11px",
      //       padding: "0px",
      //       backgroundColor: bgcolor ? bgcolor : "",
      //       marginLeft: marginLeft ? marginLeft : "",
      //       flexDirection: "column",
      //       padding: "8px 5px 0px 5px",
      //     }}
      //   >
      //     <div style={{ display: "flex", margin: "auto" }}>
          //    <div>
          //    <audio
          //     ref={this.audioRef}
          //     src="http://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Sevish_-__nbsp_.mp3"
          //   ></audio>
          //   <button onClick={this.togglePlay}>Play/Pause</button>
          // </div> 
            // <div style={{ alignSelf: "center" }}>
            //   <span class="material-symbols-outlined">headphones</span>
            // </div>
            // <div>
              <audio controls>
                <source src={src} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
      //       </div>

      //       {/* <div>
      //       <button onClick={start}>Play</button>
      //     </div> */}
      //     </div>
      //   </diV>
      // </div>
    );
  }
}
