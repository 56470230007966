import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const StyledContactWrapper = styled.div`
text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgb(58, 120, 231);
  padding: 16px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 2rem 0px;
  gap: 1rem;

  @media (min-width: 1020px) {
    gap: 6rem;
    flex-direction: row;
  }
`;

const StyledContentWrapper = styled.div`
  h1 {
    color: white;
    font-size: 30px;
    font-weight: 500;

    @media (min-width: 1020px) {
      font-size: 40px;
    }
  }
`;

const StyledButtonWrapper = styled.div`
  @media (max-width: 1020px) {
    padding-left: 0;
  }
`;

export const Contact = ({heading, btntext}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact-us");
  };

  return (
    <StyledContactWrapper>
      <StyledContentWrapper>
        <h1 dangerouslySetInnerHTML={{ __html: heading }}>
          
        </h1>
      </StyledContentWrapper>
      <StyledButtonWrapper>
        <Link to="/contact-us">
        <button
          style={{
            backgroundColor: "white",
            borderRadius: "50px",
            border: "none",
            paddingLeft: "25px",
            paddingRight: "25px",
            fontSize: "16px",
            paddingTop: "10px",
            paddingBottom: "10px",
            color: "rgb(58, 120, 231)",
          }}
        >
          {/* Get In Touch
           */}
           {btntext}
        </button>
        </Link>
      </StyledButtonWrapper>
    </StyledContactWrapper>
  );
};
