const CryptoJS = require('crypto-js');

const secretKey = CryptoJS.SHA256(process.env.REACT_APP_SECRET_KEY);

const bs = 16;

export const encryptPayload = (raw) => {
  const rawText = JSON.stringify(raw);
  const rawBuffer = CryptoJS.enc.Utf8.parse(rawText);
  const iv = CryptoJS.lib.WordArray.random(bs);
  const cipher = CryptoJS.AES.encrypt(rawBuffer, secretKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  const encrypted = iv.concat(cipher.ciphertext);
  return encrypted.toString(CryptoJS.enc.Base64);
}
