
import { Widget } from './screens/home/components/Widget';
import GlobalStyle from "./screens/home/style";
import 'bootstrap/dist/css/bootstrap.min.css';

function App({domProps}) {
  console.log("domProps", domProps);
  return (
    <>
      <GlobalStyle domProps={domProps}/>
      <Widget domProps={domProps}/>
    </>
    
  );
}

export default App;
