import React from "react";
// import { GoogleApiWrapper } from "google-maps-react";
// import { Map, InfoWindow, Marker } from "google-maps-react";

class MessageInputLocation extends React.Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.state = {
      style: {
        width: "330px",
        height: "186px",
        alignContent: "center",
        borderRadius: "11px",
        margin: "auto",
      },
    };
  }
  render() {
    let { className, bgcolor = "", marginLeft = "", styleData } = this.props;
    return (
      <div></div>
    );
  }
}

export default MessageInputLocation;
