import React, { Component } from "react";
// import "../components/style.css";
// import { Attachment } from "../../../theme";

export default class MessageInputImage extends Component {
  render() {
    let {
      // className,
      // bgcolor = "",
      // marginLeft = "",
      src,
      fileName=""
    } = this.props;
    // Just add src in img src then good to go
    return (
    <img src={`${src}`} width={250} height={"auto"} onClick={() => {
      window.open(src, "_blank");
    }}/>
    );
  }
}
