import React from 'react'
import styled from 'styled-components';
import Images from './Images';

export default function TubeLogo({ link }) {
    return (
        <NavbarLogo href="https://tubelightcommunications.com/">
            <img src={Images.Tube} />
        </NavbarLogo>
    )
}


const NavbarLogo = styled.a`
 img {
    width: 150px;
    height: auto;
 }

  @media screen and (max-width: 800px) {
    
  }
`;